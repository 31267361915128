const items = [
  {
    title: '1997',
    cardTitle: '1997',
    cardSubtitle: '',
    cardDetailedText: 'O laboratório PAS foi fundado em 1997 pelas Prof. Dra. Sílvia Limongi e Ana Virgínia Guendler, com o apoio do Prof. Dr. Valdir Bandeira.',
  },
  {
    title: '2014',
    cardTitle: '2014',
    cardSubtitle: '',
    cardDetailedText: 'Em 2014, Dra. Daniela Takano, Dr. Luiz Antônio da Fonte e Dr. José Ricardo Lima assumem a diretoria do laboratório.',
  },
  {
    title: '2016',
    cardTitle: '2016',
    cardSubtitle: '',
    cardDetailedText: 'Inauguração das filiais PAS Garanhuns e PAS Serra Talhada.',
  },
  {
    title: '2017',
    cardTitle: '2017',
    cardSubtitle: '',
    cardDetailedText: `Início da reforma estrutural da sede Recife.
    Implementação da técnica de Imuno-histoquímica

    `,
  },
  {
    title: '2018',
    cardTitle: '2018',
    cardSubtitle: '',
    cardDetailedText: 'Estabelecimento de parceria para fornecimento de exames imunofluorescência, hibridização in situ e biologia molecular. Início da realização de exames intraoperatórios por congelação. Início da realização de exames micológicos.     Inauguração da filial PAS Arcoverde. Implementação de sistema virtual de gerenciamento e emissão de laudos (PathoWeb).',
  },
  {
    title: '2020',
    cardTitle: '2020',
    cardSubtitle: '',
    cardDetailedText: 'O laboratório PAS enfrentou a pandemia, firmando-se como uma empresa sólida.',
  },
  {
    title: '2021',
    cardTitle: '2021',
    cardSubtitle: '',
    cardDetailedText: `O laboratório PAS ultrapassa a marca de 20.000 exames realizados no ano 2021.
    Término da reforma estrutural da sede Recife.`,
  },
  {
    title: '2022',
    cardTitle: '2022',
    cardSubtitle: '',
    cardDetailedText: 'Início da preparação para processo de acreditação pela Sociedade Brasileira de Patologia (PACQ-SBP).',
  },
  {
    title: '2023',
    cardTitle: '2023',
    cardSubtitle: '',
    cardDetailedText: 'Atualmente, o laboratório PAS conta com sede em Recife, três filiais, estrutura física e equipamentos de altíssima qualidade, corpo de funcionários que totaliza mais de 20 colaboladores e uma equipe médica ampliada com expertise em diversas subespecialidades.',
  },
];

export default items;

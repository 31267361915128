/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import logo from '../assets/logo_rodape.png';

function Footer() {
  return (
    <div className="absolute z-40 w-full max-[640px]:bg-neutral-500">
      <div className="flex bg-neutral-500 text-white p-4 px-32 items-center max-[640px]:flex-wrap max-[640px]:px-12">
        <ul id="col1" className="flex-auto font-bold py-2 px-1 relative">
          <Link to="/quemsomos">
            <p className="hover:text-med-cyan active:text-dark-cyan transition-all ease-in py-1">Quem Somos</p>
          </Link>
          <Link to="/certificados">
            <p className="hover:text-med-cyan active:text-dark-cyan transition-all ease-in py-1">Certificações</p>
          </Link>
          <Link to="/exames">
            <p className="hover:text-med-cyan active:text-dark-cyan transition-all ease-in py-1">Exames</p>
          </Link>
          <Link to="/nossosmedicos">
            <p className="hover:text-med-cyan active:text-dark-cyan transition-all ease-in py-1">Nossos Médicos</p>
          </Link>
          <Link to="/orientacoes">
            <p className="hover:text-med-cyan active:text-dark-cyan transition-all ease-in py-1">Orientações Ao Paciente</p>
          </Link>
          <Link to="/convenios">
            <p className="hover:text-med-cyan active:text-dark-cyan transition-all ease-in py-1">Convênios</p>
          </Link>
          <Link to="/contato">
            <p className="hover:text-med-cyan active:text-dark-cyan transition-all ease-in py-1">Contato</p>
          </Link>
          <Link to="/perguntasfrequentes">
            <p className="hover:text-med-cyan active:text-dark-cyan transition-all ease-in py-1">Perguntas Frequentes</p>
          </Link>
        </ul>
        <div id="col2" className="flex-auto py-2 px-1 relative">
          <div className="py-1">
            <div className="flex items-center">
              <Icon icon="entypo:location-pin" />
              <h4 className="uppercase text-sm">Recife</h4>
            </div>
            <p className="text-xs pl-0.5">
              Galeria Paulo Gambetá - Praça Miguel de Cervantes, 108.
              <br />
              Ilha do Leite - Recife - PE. CEP 50070-520
            </p>
            <p className="text-xs pl-0.5">Fone: (81) 3221-0115</p>
          </div>
          <div className="py-1">
            <div className="flex items-center">
              <Icon icon="entypo:location-pin" />
              <h4 className="uppercase text-sm">Arcoverde</h4>
            </div>
            <p className="text-xs pl-0.5">
              Rua Germano Magalhães, 196.
              <br />
              Centro - Arcoverde - PE. CEP 56056-550
            </p>
            <p className="text-xs pl-0.5">Fone: (87) 3822-3180</p>
          </div>
          <div className="py-1">
            <div className="flex items-center">
              <Icon icon="entypo:location-pin" />
              <h4 className="uppercase text-sm">Garanhuns</h4>
            </div>
            <p className="text-xs pl-0.5">
              Clinical Center - Av. Frei Caneca, 100 - sala 05.
              <br />
              Heliópolis - Garanhuns - PE. CEP 55296-390
            </p>
            <p className="text-xs pl-0.5">Fone: (87) 3761-7952</p>
          </div>
          <div className="py-1">
            <div className="flex items-center">
              <Icon icon="entypo:location-pin" />
              <h4 className="uppercase text-sm">Serra Talhada</h4>
            </div>
            <p className="text-xs pl-0.5">
              Galeria Consultórios Santana - Rua Agostinho Nunes
              <br />
              Magalhães, 413 - sala 01.
              Nsa Sra da Penha -
              <br />
              Serra Talhada - PE. CEP 56903-510
            </p>
            <p className="text-xs pl-0.5">Fone: (87) 3831-7305</p>
          </div>
        </div>
        <div id="col3" className="flex-auto py-2 px-1">
          <img src={logo} alt="Logo LabPas" className="mx-auto max-h-72" />
        </div>
      </div>
      <div id="devby" className="bg-neutral-600 text-center text-white p-1">
        <p>
          PAS © Copywright 2023 | Desenvolvido por
          <a href="https://bresults.com.br/"> B Results</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;

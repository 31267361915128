/* eslint-disable max-len */
/* eslint-disable no-alert */
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

function FormContact() {
  // inicializando estado do componente
  const initialState = {
    nome: '',
    sobrenome: '',
    email: '',
    telefone: '',
    mensagem: '',
  };
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  // função para controlar os inputs do componente
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const form = useRef();

  // função para enviar os dados do formulário
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    // email a ser utilizado: atendimento@labpas.com.br
    emailjs.sendForm('service_ikk3jsp', 'template_1csl45e', form.current, '46_NY71zwe2JcXds3')
      .then((result) => {
        // show the user a success message
        console.log(result);
        setFormData(initialState);
        setIsLoading(false);
        window.alert('contato enviado!');
      }, (error) => {
        // show the user an error
        console.log(error);
        window.alert('houve um erro!');
      });
    return event;
  };

  return (
    <form ref={form} onSubmit={handleSubmit} className="flex flex-col w-full max-[820px]:pt-4 max-[820px]:flex-wrap">
      <h4 className="text-2xl font-extrabold text-neutral-600 pb-1 max-[280px]:w-min">Envie um contato, sugestão ou reclamação</h4>
      <p className="text-xs leading-tight font-extralight pb-3">
        Ao preencher os campos abaixo, você consente para a coleta, armazenamento, acesso e utilização dos dados informados com a finalidade de responder o contato realizado. O PAS Patologistas Associados preza pela transparência e pelo respeito a sua privacidade, de modo que as suas informações de contato jamais serão utilizadas para finalidade diversa da informada, tampouco para envio de SPAM. Caso tenha interesse em saber mais sobre como o nosso laboratório realiza o tratamento de dados pessoais, acesse a nossa Política de Privacidade.
      </p>
      <div className="flex max-[820px]:flex-col">
        <label htmlFor="nome" className="border-neutral-600 caret-neutral-600 text-neutral-400 border text-lg my-1 p-2 flex-1">
          <input type="text" name="nome" placeholder="Nome" value={formData.nome} onChange={handleChange} className="focus:outline-none max-h-full w-full p-2 text-neutral-600" />
        </label>
        <label htmlFor="sobrenome" className="border-neutral-600 caret-neutral-600 text-neutral-400 border text-lg my-1 p-2 flex-1">
          <input type="text" name="sobrenome" placeholder="Sobrenome" value={formData.sobrenome} onChange={handleChange} className="focus:outline-none max-h-full w-full p-2 text-neutral-600" />
        </label>
      </div>
      <div className="flex max-[820px]:flex-col">
        <label htmlFor="email" className="border-neutral-600 caret-neutral-600 text-neutral-400 border text-lg my-1 p-2 flex-1">
          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} className="focus:outline-none max-h-full w-full p-2 text-neutral-600" />
        </label>
        <label htmlFor="telefone" className="border-neutral-600 caret-neutral-600 text-neutral-400 border text-lg my-1 p-2 flex-1">
          <input type="text" name="telefone" placeholder="Telefone" value={formData.telefone} onChange={handleChange} maxLength={11} className="focus:outline-none max-h-full w-full p-2 text-neutral-600" />
        </label>
      </div>
      <label htmlFor="mensagem" className="border-neutral-600 caret-neutral-600 text-neutral-400 border text-lg my-1 p-2 flex-1">
        <textarea name="mensagem" placeholder="Mensagem" value={formData.mensagem} onChange={handleChange} className="focus:outline-none h-full w-full p-2 text-neutral-600" />
      </label>
      <div />
      <button type="submit" className="bg-med-cyan p-4 mt-1 text-gray-100 max-sm:w-2/3 w-1/3 text-xl uppercase font-semibold hover:bg-dark-cyan transition-all ease-in">{isLoading ? 'Enviando...' : 'Enviar'}</button>
    </form>
  );
}

export default FormContact;

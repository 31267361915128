import React from 'react';
import { useNavigate } from 'react-router-dom';

function Button({ text, link }) {
  // possivelmente tenhamos que receber o link por prop
  // ou definir alguma condição para verificar qual será o link usado
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    // aqui faremos a chamada para o link que precisar/api whatsapp
    navigate(link);
    const test = '';
    return test;
  };

  return (
    <button
      type="button"
      alt={text}
      onClick={handleClick}
      className="py-2 px-4 bg-med-cyan font-semibold uppercase text-gray-100 text-xl rounded-2xl whitespace-nowrap hover:text-dark-blue transition-all ease-in"
    >
      {text}
    </button>
  );
}

export default Button;

/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Faq from 'react-faq-component';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import bg from '../assets/Novos_banner_faq.jpg';

const data = {
  rows: [
    {
      title: 'O que é anatomopatologia ou anatomia patológica?',
      content: `Anatomia Patológica, ou Patologia, é um ramo da Medicina que lida com o estudo das bases
            das doenças, com várias vertentes de atuação. Na prática, é uma especialidade médica cuja finalidade é
            o diagnóstico das doenças, através da análise das células e tecidos do corpo em microscópio. Com isto,
            os médicos atendentes podem direcionar a terapêutica mais adequada para cada caso.`,
    },
    {
      title: 'Qual a diferença entre um laboratório anatomopatológico e um laboratório de análises clínicas?',
      content: `Laboratórios de análises clínicas realizam exames que identificam e quantificam substâncias e
            microorganismos em líquidos e outros materiais biológicos. Os exames mais frequentes são realizados
            em sangue, urina, fezes, e as metodologias utilizadas são variadas, incluindo técnicas em bioquímica e
            microbiologia.\n
            Já o laboratório anatomopatológico trabalha não só com líquidos corporais diferentes (lavado
            bronco-alveolar, líquido ascítico, entre outros) mas também com fragmentos do corpo e peças
            cirúrgicas (órgãos inteiros ou segmentos deles retirados em procedimentos cirúrgicos). O principal
            método utilizado é o processamento do material para confecção de lâminas que são analisadas em
            microscópio.\n
            O objetivo não é quantificar substâncias, mas sim avaliando o formato das células e como
            elas se dispõem chegar ao diagnóstico de uma doença. Para isso é importante a associação das
            informações verificadas na lâmina com a história clínica do paciente, portanto o diagnóstico
            histopatológico é realizado pelo médico patologista.`,
    },
    {
      title: 'O que é patologia cirúrgica?',
      content: `É a vertente diagnóstica da Patologia, útil na prática clínica, que consiste em, através da análise
            microscópica, chegar à identificação e adequada classificação das doenças. A patologia cirúrgica
            envolve o processamento e avaliação de materiais de biópsias e peças cirúrgicas (órgãos inteiros ou
            segmentos deles retirados em procedimentos cirúrgicos). `,
    },
    {
      title: 'O que é citopatologia?',
      content: `Citopatologia é o estudo das células e suas alterações em casos de doenças. As amostras em
            citopatologia abrangem líquidos e esfregaços/imprints, tipos de materiais em que as células estão mais
            soltas e não compondo a arquitetura de um tecido ou órgão.`,
    },
    {
      title: 'O médico solicitou uma biópsia, significa que estou com câncer?',
      content: `Na grande maioria dos casos não. A biópsia e estudo histopatológico são solicitados em
          diversas situações clínicas, que incluem tanto a investigação de condições benignas como malignas. As
          situações benignas incluem doenças inflamatórias, infecciosas e neoplasias benignas. A suspeita de
          uma neoplasia maligna (câncer) é um importante motivo para se solicitar uma biópsia, mas o que se
          verifica é que a maior parcela de laudos histopatológicos liberados corresponde a diagnósticos de
          doenças benignas. De qualquer forma, é imprescindível a análise patológica para afastar ou confirmar o
          câncer. Em casos de positividade, o correto e rápido diagnóstico é imprescindível para início do melhor
          tratamento o mais breve possível.`,
    },
    {
      title: 'O que é histopatologia?',
      content: `É o método da Patologia em que o material (fragmento ou peça cirúrgica) é avaliado
          macroscopicamente, sendo então submetido a processos químicos e físicos para confecção de um
          bloco de parafina contendo o material. Esse bloco é cortado em espessura finíssima, que permite a
          visualização das células que compõem o material; os cortes são posicionados em uma lâmina, corados
          e avaliados em microscópio. A histopatologia se refere à análise morfológica (formato e coloração) das
          células e tecidos na lâmina, após o processamento adequado.`,
    },
    {
      title: 'O que é imuno-histoquímica?',
      content: `Procedimento diagnóstico complementar à análise morfológica de rotina, que revela expressão
          (ou ausência) de determinados antígenos nas células e tecidos. Antígenos são moléculas capazes de
          serem identificadas por anticorpos. O método imuno-histoquímico utiliza justamente anticorpos
          produzidos em laboratório para identificar tais moléculas. A depender de quais antígenos são
          detectados ou não, diferentes diagnósticos são favorecidos ou afastados.`,
    },
    {
      title: 'Como funciona a técnica de colorações histoquímicas especiais?',
      content: `O corante usual para o método histopatológico é a hematoxilia e eosina (H&amp;E), mas outras
          colorações podem ser necessárias para a conclusão diagnóstica. São realizados cortes extras do bloco
          de parafina que contém o material e tais cortes são corados por colorações alternativas ao H&amp;E. Tais
          colorações auxiliam ora realçando estruturas que o H&amp;E não realça (exemplo: a coloração Alcian Blue
          realça mucina, a coloração Masson realça fibras colágenas) ora marcando microorganismos infecciosos
          (exemplo: a coloração Grocott revela estruturas fúngicas).`,
    },
    {
      title: 'O que é imunofluorescência?',
      content: `Exame realizado em material não fixado (a fresco) e transportado em meio de preservação
          adequado (meio de Michel), que utiliza anticorpos e fluorocromos (corantes que absorvem radiação luz
          ultravioleta) para demonstrar a presença de imunoglobulinas e/ou proteínas do complemento no
          material, auxiliando no diagnóstico e classificação de lesões de pele e rim.`,
    },
    {
      title: 'Todo material extraído do corpo humano precisa ser analisado?',
      content: `Segundo o parecer do Conselho Federal de Medicina CFM nº 30/2019, não é obrigatória a
          solicitação de exame anatomopatológico de todo material retirado do corpo. Contudo é imprescindível
          que cada situação em particular passe pela avaliação do médico assistente quanto à necessidade ou
          não de realização do exame histopatológico, levando em consideração possíveis desvios da
          normalidade e a oportunidade diagnóstica de patologias não esperadas.`,
    },
    {
      title: 'Como guardar e transportar amostras biológicas?',
      content: 'Vide Manual de coleta, acondicionamento, preservação e transporte de amostras do laboratório PAS.',
    },
    {
      title: 'Por quanto tempo o laboratório guarda amostras biológicas dos pacientes?',
      content: `O laboratório PAS segue as normas/legislações pertinentes e as orientações da Sociedade Brasileira de
          Patologia quanto ao tempo de arquivamento de amostras, blocos de parafina, lâminas e laudos:
          Espécimes (amostras) – três (3) meses;
          Blocos de parafina – dez (10) anos;
          Lâminas de citopatologia positivas – 10 anos;
          Lâminas de histopatologia, citopatologia negativa, imuno-histoquímica – cinco (5) anos;
          Laudos – meio eletrônico de forma permanente.`,
    },
  ],
};

const styles = {
  /* bgColor: 'white', */
  /* titleTextColor: '#256D78', */
  rowTitleColor: '#00679a',
  rowContentColor: '#00679a',
  arrowColor: '#00679a',
  fontFamily: 'Sans-serif',
  rowTitleTextSize: '24px',
  rowContentTextSize: '18px',
  whiteSpace: 'pre-wrap',
};

/* const Faqstyle = {
  margin: '3%',
}; */

const config = {
  animate: true,
  arrowIcon: '⌄',
  openOnload: 0,
  expandIcon: '⌄',
  collapseIcon: '⌄',
};

function FAQ() {
  return (
    <div>
      <Navbar />
      <Banner image={bg} text="perguntas frequentes" />
      <div className="mx-32 my-10">
        <Faq
          data={data}
          styles={styles}
          config={config}
        />
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;

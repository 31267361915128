/* eslint-disable */
import React from 'react';
import convertli from '../utils/convertli';

function CardDoctor(props) {
  const {
    image, doctor, title, caption, text,
  } = props.data;

  const textli = convertli(text)
  // console.log('textli @', textli);

  return (
    <div className="p-4 px-12 flex items-center max-md:flex-col max-md:px-4">
      <div className='w-1/4 overflow-clip rounded-3xl max-md:w-full'>
        <img src={image} alt={`foto de ${doctor}`} className="" />
      </div>
      <div id="col2" className="flex-1 p-4 md:ml-4">
        <h3 className="text-3xl max-md:text-2xl text-med-cyan font-extrabold mb-2">
          {doctor}
        </h3>
        <h5 className="text-xl text-med-cyan font-bold leading-none max-md:text-base/tight mb-2">{title}</h5>
        <h6 className="text-dark-cyan font-semibold text-base leading-tight my-1">{caption}</h6>
        <ul className="marker:text-med-cyan list-disc p-4 text-dark-blue font-semibold text-sm leading-tight max-[400px]:text-xs">
          {textli.map((item) => <li key={item}>{item}</li>)}
        </ul>
      </div>
    </div>
  );
}

export default CardDoctor;

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate/* , Link */ } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Timeline from '../components/Timeline';
import TimelineMobile from '../components/TimelineMobile';
import Carousel from '../components/Carousel';
import CarouselMobile from '../components/CarouselMobile';

import './animations.css';

import Laudo from '../assets/bg_acesse_seu_laudo.png';
import Recife from '../assets/recife_cor.png';
import Arcoverde from '../assets/arcoverde_cor.png';
import Garanhuns from '../assets/garanhuns_cor.png';
import Serratalhada from '../assets/serratalhada_cor.png';
import capa from '../assets/capa-versão2.jpg';

function Home() {
  const screenSize = window.innerWidth;
  const [tlOpen, setTlOpen] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  function handleAccess() {
    navigate('/login');
  }

  const handleToggle = () => {
    setTlOpen(!tlOpen);

    // Scroll the page when the container expands or collapses
    if (containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'start',
      });
    }
  };

  return (
    <>
      <Navbar />
      <HashLink to="/contato#contactform">
        <div className="flex-1 flex items-center fixed -rotate-90 text-lg max-sm:text-base lg:text-2xl text-med-cyan z-50 p-2 lg:p-4 m-0 bg-dark-blue -right-12 lg:-right-14 top-96 drop-shadow-md cursor-pointer hover:text-gray-100 hover:p-3 max-sm:hover:text-lg lg:hover:p-6 hover:text-2xl transition-all ease-linear">
          <Icon icon="material-symbols:mark-chat-read-outline" width="24" height="24" className="rotate-90" />
          <button type="button" className="p-2 uppercase">Feedback</button>
        </div>
      </HashLink>
      <div className="w-full flex relative h-80 bg-gradient-to-r from-med-cyan to-transparent overflow-clip">
        <div className="flex flex-col items-center justify-center h-full w-1/2 max-sm:w-full absolute">
          <h2 className="lg:text-4xl text-3xl max-md:text-xl leading-tight text-left font-bold text-gray-100 w-2/3 mb-8">
            Laboratório médico de anatomia patológica em Pernambuco
          </h2>
          <div className="w-2/3 flex justify-start">
            <button type="button" onClick={() => navigate('/quemsomos')} className="py-2 px-4 bg-dark-blue font-semibold uppercase text-gray-100 text-xl rounded-2xl whitespace-nowrap hover:text-med-cyan hover:bg-white transition-all ease-linear">
              Saiba mais
            </button>
          </div>
        </div>
        <div className="w-full -z-10 bg-right bg-no-repeat bg-cover max-sm:bg-center" style={{ backgroundImage: `url(${capa})` }} />
      </div>
      <div>
        <div className="flex items-center justify-center relative max-h-72 overflow-clip">
          <img src={Laudo} alt="laudo" className="bg-cover bg-opacity-100" />
          <div className="flex-1 absolute">
            <button type="button" onClick={handleAccess} className="transition-all ease-linear py-4 px-4 sm:py-12 sm:px-12  border-med-cyan border-2 font-semibold uppercase text-gray-100 text-2xl max-[365px]:text-base rounded-3xl my-6 lg:text-5xl lg:whitespace-nowrap hover:bg-med-cyan">Acesse aqui o seu laudo</button>
          </div>
        </div>
      </div>
      <div className="max-sm:text-center flex justify-center my-8 p-4 text-med-cyan uppercase text-3xl lg:text-5xl font-bold">
        <h4>Conheça nossas unidades</h4>
      </div>
      <div className="flex items-center justify-center content-center px-8 py-4 mb-12 max-lg:flex-wrap lg:mx-10 xl:mx-32">
        <div className="relative mx-4 mb-4 text-dark-blue hover:opacity-100 hover:text-gray-50 text-3xl hover:text-4xl overflow-clip transition-all ease-in max-sm:text-2xl max-sm:hover:text-3xl">
          <HashLink to="/contato#conteudo" state={{ from: 'recife' }}>
            <img src={Recife} alt="recife" className=" opacity-30 cursor-pointer hover:opacity-100 transition-all ease-in-out" />
            <p className="uppercase text-center font-bold absolute bottom-20 inset-x-0 hover:text-dark-blue">recife</p>
          </HashLink>
          {/* <a href="/contato">
            <img src={Recife} alt="recife" className=" opacity-30 cursor-pointer hover:opacity-100 transition-all ease-in-out" />
            <p className="uppercase text-center font-bold absolute bottom-20 inset-x-0 hover:text-dark-blue">recife</p>
          </a> */}
        </div>
        <div className="relative mx-4 mb-4 text-dark-blue hover:opacity-100 hover:text-gray-50 text-3xl hover:text-4xl overflow-clip transition-all ease-in max-sm:text-2xl max-sm:hover:text-3xl">
          <HashLink to="/contato#conteudo" state={{ from: 'arcoverde' }}>
            <img src={Arcoverde} alt="Arcoverde" className=" opacity-30 cursor-pointer hover:opacity-100 transition-all ease-in-out" />
            <p className="uppercase text-center font-bold absolute bottom-20 inset-x-0 hover:text-dark-blue">arcoverde</p>
          </HashLink>
          {/* <a href="/contato">
            <img src={Arcoverde} alt="Arcoverde" className=" opacity-30 cursor-pointer hover:opacity-100 transition-all ease-in-out" />
            <p className="uppercase text-center font-bold absolute bottom-20 inset-x-0 hover:text-dark-blue">arcoverde</p>
          </a> */}
        </div>
        <div className="relative mx-4 mb-4 text-dark-blue hover:opacity-100 hover:text-gray-50 text-3xl hover:text-4xl overflow-clip transition-all ease-in max-sm:text-2xl max-sm:hover:text-3xl">
          <HashLink to="/contato#conteudo" state={{ from: 'garanhuns' }}>
            <img src={Garanhuns} alt="Garanhuns" className=" opacity-30 cursor-pointer hover:opacity-100 transition-all ease-in-out" />
            <p className="uppercase text-center font-bold absolute bottom-20 inset-x-0 hover:text-dark-blue">garanhuns</p>
          </HashLink>
          {/* <a href="/contato">
            <img src={Garanhuns} alt="Garanhuns" className=" opacity-30 cursor-pointer hover:opacity-100 transition-all ease-in-out" />
            <p className="uppercase text-center font-bold absolute bottom-20 inset-x-0 hover:text-dark-blue">garanhuns</p>
          </a> */}
        </div>
        <div className="relative mx-4 mb-4 text-dark-blue hover:opacity-100 hover:text-gray-50 text-3xl hover:text-4xl overflow-clip transition-all ease-in max-sm:text-2xl max-sm:hover:text-3xl">
          <HashLink to="/contato#conteudo" state={{ from: 'serratalhada' }}>
            <img src={Serratalhada} alt="Serra Talhada" className=" opacity-30 cursor-pointer hover:opacity-100 transition-all ease-in-out" />
            <p className="uppercase text-center font-bold absolute bottom-20 inset-x-0 hover:text-dark-blue">serra talhada</p>
          </HashLink>
          {/* <a href="/contato">
            <img src={Serratalhada} alt="Serra Talhada" className=" opacity-30 cursor-pointer hover:opacity-100 transition-all ease-in-out" />
            <p className="uppercase text-center font-bold absolute bottom-20 inset-x-0 hover:text-dark-blue">serra talhada</p>
          </a> */}
        </div>
      </div>
      <div id="timeline" className="flex relative max-sm:flex-col w-full">
        <h4 className="w-1/3 max-sm:w-full px-16 py-32 max-sm:py-12 bg-gradient-to-r from-med-cyan absolute max-sm:bg-gradient-to-b text-gray-100 uppercase text-5xl font-bold z-40 h-full max-sm:h-1/4">
          Nossa <br className="max-sm:hidden" /> História
        </h4>
        <div className="ml-80 max-sm:hidden w-full overflow-hidden">
          <Timeline />
        </div>
        <div ref={containerRef} className={`sm:hidden mt-48 slide-container pb-20 ${tlOpen ? 'slide-down' : 'slide-up'}`}>
          <TimelineMobile />
        </div>
        <div className={`${tlOpen ? '' : ''} sm:hidden absolute z-40 bottom-0 inset-x-0 pb-4 h-20 bg-gradient-to-t from-med-cyan flex items-end justify-center`}>
          <button type="button" onClick={handleToggle} className="py-2 px-4 bg-white font-semibold uppercase text-med-cyan text-xl rounded-2xl whitespace-nowrap">{tlOpen ? 'VER MENOS' : 'VER MAIS'}</button>
        </div>
      </div>
      <div className="bg-med-cyan text-center text-white w-full pt-6 pb-10">
        <h4 className="text-2xl lg:text-5xl py-8 font-bold">Estrutura moderna e equipamentos de ponta</h4>
        <div className="flex py-8 px-4 max-xl:flex-wrap lg:mx-32">
          <div className="flex-1 mx-1 my-1">
            <h1 className="text-6xl lg:text-9xl font-semibold">25</h1>
            <p className="text-2xl border-t-2 border-white mx-20 max-[385px]:mx-12">
              anos de atendimento
            </p>
          </div>
          <div className="flex-1 mx-1 my-1">
            <h1 className="text-6xl lg:text-9xl font-semibold">04</h1>
            <p className="text-2xl border-t-2 border-white mx-20 max-[385px]:mx-12">unidades de atendimento</p>
          </div>
          <div className="flex-1 mx-1 my-1">
            <h1 className="text-6xl lg:text-9xl font-semibold">+10</h1>
            <p className="text-2xl border-t-2 border-white mx-20 max-[385px]:mx-12">médicos especialistas</p>
          </div>
          <div className="flex-1 mx-1 my-1">
            <h1 className="text-6xl lg:text-9xl font-semibold">+20</h1>
            <p className="text-2xl border-t-2 border-white mx-20 max-[385px]:mx-12">colaboradores</p>
          </div>
        </div>
      </div>
      <div>
        {screenSize > 768 ? <Carousel /> : <CarouselMobile />}
      </div>
      <Footer />
    </>
  );
}

export default Home;

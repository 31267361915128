/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Button from '../components/Button';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import FormContact from '../components/FormContact';
import bg from '../assets/Novos_banner_contato.png';
import foto from '../assets/foto_contato.jpg';
import vetor from '../assets/arabesco_2.png';

// foi necessario colocar um onclick separado no componente icon
// estava dando problema usando só na div

// iframes das localizações de cada unidade
const recife = (
  <iframe
    title="map-recife"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.3390124559323!2d-34.89856057793561!3d-8.066861475614168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab18cfb5dcc9bd%3A0x7a662f536b6a530!2sPra%C3%A7a%20Miguel%20de%20Cervantes%2C%20108%20-%20Ilha%20do%20Leite%2C%20Recife%20-%20PE%2C%2050070-520!5e0!3m2!1spt-BR!2sbr!4v1681141264689!5m2!1spt-BR!2sbr"
    width="600"
    height="400"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="eager"
    referrerPolicy="no-referrer-when-downgrade"
  />
);
const arcoverde = (
  <iframe
    title="map-recife"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3946.7985529009525!2d-37.058175885397276!3d-8.421434993943725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7a81be9813b15af%3A0x97c6e78e63d73e47!2sR.%20Germano%20Magalh%C3%A3es%2C%20196%20-%20Centro%2C%20Arcoverde%20-%20PE%2C%2056506-550!5e0!3m2!1spt-BR!2sbr!4v1681153229889!5m2!1spt-BR!2sbr"
    width="600"
    height="400"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="eager"
    referrerPolicy="no-referrer-when-downgrade"
  />
);
const garanhuns = (
  <iframe
    title="map-recife"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.9274389976736!2d-36.48185898539369!3d-8.886338593619884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7070d1ffd68a4e9%3A0x9154088b56d4eb0f!2sAv.%20Frei%20Caneca%2C%20100%20-%20Heli%C3%B3polis%2C%20Garanhuns%20-%20PE%2C%2055296-390!5e0!3m2!1spt-BR!2sbr!4v1681154311569!5m2!1spt-BR!2sbr"
    width="600"
    height="400"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="eager"
    referrerPolicy="no-referrer-when-downgrade"
  />
);
const serratalhada = (
  <iframe
    title="map-recife"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3951.107918078561!2d-38.30276458540027!3d-7.987790694247202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7a71639f4362b4f%3A0xc1bda7dbc60b1921!2sR.%20Agostinho%20Nunes%20de%20Magalh%C3%A3es%2C%20413%20-%20Nossa%20Sra.%20da%20Penha%2C%20Serra%20Talhada%20-%20PE%2C%2056903-510!5e0!3m2!1spt-BR!2sbr!4v1681154421545!5m2!1spt-BR!2sbr"
    width="600"
    height="400"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="eager"
    referrerPolicy="no-referrer-when-downgrade"
  />
);
// iframes mobile
const recifeMobile = (
  <iframe
    title="map-recife"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.3390124559323!2d-34.89856057793561!3d-8.066861475614168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab18cfb5dcc9bd%3A0x7a662f536b6a530!2sPra%C3%A7a%20Miguel%20de%20Cervantes%2C%20108%20-%20Ilha%20do%20Leite%2C%20Recife%20-%20PE%2C%2050070-520!5e0!3m2!1spt-BR!2sbr!4v1681141264689!5m2!1spt-BR!2sbr"
    width="300"
    height="500"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="eager"
    referrerPolicy="no-referrer-when-downgrade"
  />
);
const arcoverdeMobile = (
  <iframe
    title="map-recife"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3946.7985529009525!2d-37.058175885397276!3d-8.421434993943725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7a81be9813b15af%3A0x97c6e78e63d73e47!2sR.%20Germano%20Magalh%C3%A3es%2C%20196%20-%20Centro%2C%20Arcoverde%20-%20PE%2C%2056506-550!5e0!3m2!1spt-BR!2sbr!4v1681153229889!5m2!1spt-BR!2sbr"
    width="300"
    height="500"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="eager"
    referrerPolicy="no-referrer-when-downgrade"
  />
);
const garanhunsMobile = (
  <iframe
    title="map-recife"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.9274389976736!2d-36.48185898539369!3d-8.886338593619884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7070d1ffd68a4e9%3A0x9154088b56d4eb0f!2sAv.%20Frei%20Caneca%2C%20100%20-%20Heli%C3%B3polis%2C%20Garanhuns%20-%20PE%2C%2055296-390!5e0!3m2!1spt-BR!2sbr!4v1681154311569!5m2!1spt-BR!2sbr"
    width="300"
    height="500"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="eager"
    referrerPolicy="no-referrer-when-downgrade"
  />
);
const serratalhadaMobile = (
  <iframe
    title="map-recife"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3951.107918078561!2d-38.30276458540027!3d-7.987790694247202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7a71639f4362b4f%3A0xc1bda7dbc60b1921!2sR.%20Agostinho%20Nunes%20de%20Magalh%C3%A3es%2C%20413%20-%20Nossa%20Sra.%20da%20Penha%2C%20Serra%20Talhada%20-%20PE%2C%2056903-510!5e0!3m2!1spt-BR!2sbr!4v1681154421545!5m2!1spt-BR!2sbr"
    width="300"
    height="500"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="eager"
    referrerPolicy="no-referrer-when-downgrade"
  />
);

function Contato() {
  // logica para verificar de onde veio e renderizar o mapa de acordo
  const location = useLocation();
  const { from } = location.state || 'recife';

  // inicializando estado da pagina
  const [selectedAddress, setSelectedAddress] = useState(from || 'recife');

  // função que controla o endereço selecionado e coloca no estado
  const handleSelectedAddress = (event) => {
    setSelectedAddress(event.target.id);
    return selectedAddress;
  };

  // condicional verifica o endereço selecionado atualmente e renderiza mapa de acordo
  const renderActiveMap = () => {
    switch (selectedAddress) {
      case 'serratalhada':
        return serratalhada;
      case 'arcoverde':
        return arcoverde;
      case 'garanhuns':
        return garanhuns;
      default:
        return recife;
    }
  };
  // condicional para renderizar mapa mobile
  const renderActiveMapMobile = () => {
    switch (selectedAddress) {
      case 'serratalhada':
        return serratalhadaMobile;
      case 'arcoverde':
        return arcoverdeMobile;
      case 'garanhuns':
        return garanhunsMobile;
      default:
        return recifeMobile;
    }
  };

  return (
    <>
      <Navbar id="top" />
      <Banner text="contato" image={bg} />
      <div id="conteudo" className="relative flex items-center justify-center py-12 overflow-clip max-[1024px]:flex-wrap sm:ml-12 xl:ml-32">
        <img src={vetor} alt="imagem decorativa" className="absolute -z-10 rotate-180 -top-28 -right-8 w-60 h-48" />
        <div className="pr-8 max-sm:hidden">
          {
            renderActiveMap(selectedAddress)
          }
        </div>
        <div className="sm:hidden mb-5">
          {
            renderActiveMapMobile(selectedAddress)
          }
        </div>
        <div id="enderecos" className="flex-auto text-neutral-600 py-2 px-1 mx-2">
          <div id="recife" className={`py-1 hover:text-red-600 cursor-pointer w-fit ${selectedAddress === 'recife' ? 'text-red-600' : 'text-neutral-600'}`} role="button" tabIndex="0" onKeyDown={handleSelectedAddress} onClick={handleSelectedAddress}>
            <button id="recife" type="button" className="flex items-center uppercase text-lg font-semibold">
              <Icon icon="entypo:location-pin" id="recife" onClick={handleSelectedAddress} />
              Recife
            </button>
            <p id="recife" className="text-base pl-0.5 leading-tight">
              Galeria Paulo Gambetá - Praça Miguel de Cervantes, 108.
              <br />
              Ilha do Leite - Recife - PE. CEP 50070-520
            </p>
            <p id="recife" className="text-base pl-0.5 leading-tight">Fone: (81) 3221-0115</p>
          </div>
          <div id="arcoverde" className={`py-1 hover:text-red-600 cursor-pointer w-fit ${selectedAddress === 'arcoverde' ? 'text-red-600' : 'text-neutral-600'}`} role="button" tabIndex="0" onKeyDown={handleSelectedAddress} onClick={handleSelectedAddress}>
            <button type="button" id="arcoverde" className="flex items-center uppercase text-lg font-semibold">
              <Icon icon="entypo:location-pin" id="arcoverde" onClick={handleSelectedAddress} />
              Arcoverde
            </button>
            <p id="arcoverde" className="text-base pl-0.5 leading-tight">
              Rua Germano Magalhães, 196.
              <br />
              Centro - Arcoverde - PE. CEP 56056-550
            </p>
            <p id="arcoverde" className="text-base pl-0.5 leading-tight">Fone: (87) 3822-3180</p>
          </div>
          <div id="garanhuns" className={`py-1 hover:text-red-600 cursor-pointer w-fit ${selectedAddress === 'garanhuns' ? 'text-red-600' : 'text-neutral-600'}`} role="button" tabIndex="0" onKeyDown={handleSelectedAddress} onClick={handleSelectedAddress}>
            <button id="garanhuns" type="button" className="flex items-center uppercase text-lg font-semibold">
              <Icon icon="entypo:location-pin" id="garanhuns" onClick={handleSelectedAddress} />
              Garanhuns
            </button>
            <p id="garanhuns" className="text-base pl-0.5 leading-tight">
              Clinical Center - Av. Frei Caneca, 100 - sala 05.
              <br />
              Heliópolis - Garanhuns - PE. CEP 55296-390
            </p>
            <p id="garanhuns" className="text-base pl-0.5 leading-tight">Fone: (87) 3761-7952</p>
          </div>
          <div id="serratalhada" className={`py-1 hover:text-red-600 cursor-pointer w-fit ${selectedAddress === 'serratalhada' ? 'text-red-600' : 'text-neutral-600'}`} role="button" tabIndex="0" onKeyDown={handleSelectedAddress} onClick={handleSelectedAddress}>
            <button id="serratalhada" type="button" className="flex items-center uppercase text-lg font-semibold">
              <Icon icon="entypo:location-pin" id="serratalhada" onClick={handleSelectedAddress} />
              Serra Talhada
            </button>
            <p id="serratalhada" className="text-base pl-0.5 leading-tight">
              Galeria Consultórios Santana - Rua Agostinho Nunes
              <br />
              Magalhães, 413 - sala 01.
              Nsa Sra da Penha -
              <br />
              Serra Talhada - PE. CEP 56903-510
            </p>
            <p id="serratalhada" className="text-base pl-0.5 leading-tight">Fone: (87) 3831-7305</p>
          </div>
        </div>
      </div>
      <div className="flex bg-neutral-200 p-4 items-center justify-center max-[820px]:flex-wrap">
        <h2 className="text-3xl font-extrabold text-neutral-600 py-2 max-sm:text-center">Confira as principais dúvidas!</h2>
        <div className="bg-med-cyan h-fit w-fit mx-5 p-2">
          <Button text="Acesse nosso FAQ" link="/perguntasfrequentes" />
        </div>
      </div>
      <div id="contactform" className="flex py-12 mx-10 lg:px-32 max-[820px]:flex-wrap max-[820px]:justify-center max-[360px]:px-10">
        <div className="md:w-1/3 mr-4 max-sm:mr-0">
          <img src={foto} alt="foto em destaque" className="object-contain" />
        </div>
        <FormContact />
      </div>
      <Footer />
    </>
  );
}

export default Contato;

import React from 'react';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import InfoModal from '../components/Modal';
import Footer from '../components/Footer';

import bg from '../assets/Novos_banner_convenios.jpg';
import vetor from '../assets/arabesco_2.png';
import conv1 from '../assets/convenios/plano_convenios_1.png';
import conv2 from '../assets/convenios/plano_convenios_2.png';
import conv3 from '../assets/convenios/plano_convenios_3.png';
import conv4 from '../assets/convenios/plano_convenios_4.png';
import conv5 from '../assets/convenios/plano_convenios_5.png';
import conv6 from '../assets/convenios/plano_convenios_6.png';
import conv7 from '../assets/convenios/plano_convenios_7.png';
import conv8 from '../assets/convenios/plano_convenios_8.png';
import conv9 from '../assets/convenios/plano_convenios_9.png';
import conv10 from '../assets/convenios/plano_convenios_10.png';
import conv11 from '../assets/convenios/plano_convenios_11.png';
import conv12 from '../assets/convenios/plano_convenios_12.png';
import conv13 from '../assets/convenios/plano_convenios_13.png';
import conv14 from '../assets/convenios/plano_convenios_14.png';
import conv15 from '../assets/convenios/plano_convenios_15.png';
import conv16 from '../assets/convenios/plano_convenios_16.png';

const convArr = [
  { image: conv1, conv: 'Unimed' },
  { image: conv2, conv: 'SulAmérica' },
  { image: conv3, conv: 'Cassi' },
  { image: conv4, conv: 'Fachesf' },
  { image: conv5, conv: 'Fisco Saúde' },
  { image: conv6, conv: 'Saúde Caixa' },
  { image: conv7, conv: 'Banco Central Saúde' },
  { image: conv8, conv: 'Geap Saúde' },
  { image: conv9, conv: 'Postal Saúde' },
  { image: conv10, conv: 'Conab' },
  { image: conv11, conv: 'Plan-Assiste' },
  { image: conv12, conv: 'Fusex' },
  { image: conv13, conv: 'Marinha do Brasil' },
  { image: conv14, conv: 'Força Aérea Brasileira' },
  { image: conv15, conv: 'TRT6 Saúde Pernambuco' },
  { image: conv16, conv: 'Campe' },
];

function Convenios() {
  return (
    <div className="relative">
      <Navbar />
      <Banner text="convênios" image={bg} />
      <InfoModal information="O laboratório PAS disponibiliza MANUAL DE COLETA, ACONDICIONAMENTO E TRANSPORTE DE AMOSTRAS, com o objetivo de assegurar que o transporte dos materiais ocorra de forma satisfatória. O manual contém informações técnicas que auxiliam nesta tarefa. Mas, antes de qualquer coisa, converse e siga as orientações do seu médico. Caso alguma dúvida persista, entre em contato conosco!" />
      <div className="flex justify-center items-center">
        <div className="w-fit grid max-[420px]:grid-cols-1 max-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-x-10 gap-y-10 lg:mx-32 my-20 place-items-center place-content-center">
          {convArr.map((item) => <div className="p-2 w-40 h-40 flex items-center justify-center"><img key={item.conv} src={item.image} alt={item.conv} className="max-h-full object-contain" /></div>)}
        </div>

      </div>
      <img src={vetor} alt="imagem decorativa" className="absolute -bottom-32 -left-32 rotate-90 w-72 h-60 -z-10" />
      <Footer />
    </div>
  );
}

export default Convenios;

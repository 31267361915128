/* eslint-disable max-len */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Icon } from '@iconify/react';
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';

import './carousel.css';

import data from '../data/carousel';

function CarouselMobile() {
  useEffect(() => {
    new Glide('.glide', {
      type: 'slider',
      autoplay: 0,
      animationTimingFunc: 'linear',
      animationDuration: 600,
      perView: 1,
      startAt: 1,
      gap: 40,
      peek: 50,
      focusAt: 'center',
      keyboard: true,
    }).mount();
  }, []);

  return (
    <div className="glide relative bg-white overflow-hidden">
      <div className="glide__arrows my-2 text-center absolute left-0 z-50 top-32" data-glide-el="controls">
        <button id="prev" type="button" className="glide__arrow glide__arrow--prev " data-glide-dir="<"><Icon icon="line-md:chevron-left-circle" width={48} height={48} className="text-dark-cyan bg-white rounded-full" /></button>
      </div>
      <div className="glide__track pt-8 pb-4 relative" data-glide-el="track">
        <ul className="glide__slides w-2/3">
          {data.map((item) => (
            <li key={item.title} className="glide__slide py-3 px-4 border-2 border-dark-blue rounded-tl-lg rounded-tr-3xl rounded-b-3xl uppercase font-bold text-dark-cyan text-center overflow-clip">
              <HashLink to="/exames#conteudo">
                <img src={item.icon} alt="icone ilustrativo" className="object-contain" />
                <div className="py-3 px-1 flex flex-col items-center justify-center">
                  <h1 className="text-3xl/none max-lg:text-lg/none mb-2">{item.title}</h1>
                </div>
              </HashLink>
            </li>
          ))}

        </ul>

        <div className="glide__arrows my-2 text-center absolute right-0 z-50 top-32" data-glide-el="controls">
          <button type="button" className="glide__arrow glide__arrow--next" data-glide-dir=">"><Icon icon="line-md:chevron-right-circle" width={48} height={48} className="text-dark-cyan bg-white rounded-full" /></button>
        </div>
      </div>
      <div>
        <div className="glide__bullets flex justify-center mb-2" data-glide-el="controls[nav]">
          {data.map(({ id }) => <button key={id} type="button" className="glide__bullet p-1 bg-dark-blue/60 m-2 rounded-full hover:bg-dark-blue cursor-pointer" data-glide-dir={`=${id}`} />)}
        </div>
      </div>
    </div>
  );
}

export default CarouselMobile;

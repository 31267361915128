/* eslint-disable max-len */
import React, { useState } from 'react';
import Button from '../components/Button';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import LoginForm from '../components/LoginFormP';
import logo from '../assets/logo_colorida.png';
import bg from '../assets/bg_tela_login.png';
// import vetor from '../assets/arabesco_tela_login.png';
import bvetor from '../assets/arabesco_2.png';
import icondr from '../assets/icons-acesso-03.png';
import iconp from '../assets/icons-acesso-02.png';

function Login() {
  const [currUser, setCurrUser] = useState('');
  // console.log('currUser @login', currUser);

  function handleUser(e) {
    setCurrUser(e.target.name);
  }

  return (
    <>
      <Navbar />
      <div className="w-full bg-center bg-cover bg-fixed bg-opacity-100 relative" style={{ backgroundImage: `url(${bg})` }}>
        <img src={bvetor} alt="imagem decorativa" className="absolute -z-10 opacity-50 -bottom-8 -left-8" />
        <div className="p-8 pt-16">
          <img src={logo} alt="Logo LabPas" className="mx-auto w-72" />
        </div>
        <div className="flex justify-center p-12 max-sm:flex-col max-sm:items-center">
          <button type="button" name="medico" onClick={handleUser} onKeyDown={handleUser} tabIndex={0} className="cursor-pointer my-4 py-8 px-16 border border-dark-blue rounded-3xl text-dark-blue font-semibold w-min hover:bg-dark-blue hover:text-gray-100 transition-all ease-in mx-10 max-sm:mx-2 text-xl uppercase mt-4 text-center">
            <img src={icondr} alt="icone médico" name="medico" className="mb-3" />
            eu sou médico
            {/*             <button type="button" name="medico" alt="login médico" onClick={handleUser} className="text-xl uppercase mt-4"></button> */}
          </button>
          <button type="button" name="paciente" onClick={handleUser} onKeyDown={handleUser} tabIndex={0} className="cursor-pointer my-4 py-8 px-16 border border-dark-blue rounded-3xl text-dark-blue font-semibold w-min hover:bg-dark-blue hover:text-gray-100 transition-all ease-in mx-10 max-sm:mx-2 text-xl uppercase mt-4">
            <img src={iconp} alt="icone paciente" name="paciente" className="mb-3" />
            eu sou cliente
            {/* <button type="button" name="paciente" alt="login cliente" onClick={handleUser} className=""></button> */}
          </button>
        </div>
        <div>
          {currUser !== ''
            ? (
              <div className="absolute z-50 top-60 w-full">
                <button type="button" className="bg-med-cyan font-semibold text-center py-1 px-3 rounded-full absolute right-0 top-0" onClick={() => setCurrUser('')}>
                  x
                </button>
                <LoginForm user={currUser} />
              </div>
            )
            : null}

        </div>
        <div className="p-4 w-fit mx-auto top-0 pb-24">
          <Button alt="VOLTAR" text="VOLTAR" link="/" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;

import iconeHP from '../assets/icones/Icon_Histopatologico.png';
import iconeHQ from '../assets/icones/Icon_ImunoHisto.png';
import iconeCH from '../assets/icones/Icon_Colorações.png';
import iconeIF from '../assets/icones/Icon_Imunoflorescencia.png';
import iconeCG from '../assets/icones/Icon_Citopatologia.png';
import iconePC from '../assets/icones/Icon_CitoCervico.png';
import iconeEIC from '../assets/Icon_ExameIntraCongelação.png';
import iconeRL from '../assets/icones/Icon_Revisão Laminas.png';
import iconeCL from '../assets/icones/Icon_ColetaTzack.png';
import iconeBA from '../assets/icones/Icon_BaciloscopiaAtipica.png';
import iconePM from '../assets/icones/Icon_PesquisaMicroscopicaDiretaProtozoarios.png';
import iconePF from '../assets/icones/Icon_PesquisaMicroscopicaDiretaFungos.png';
import iconeCF from '../assets/icones/Icon_Culturafungo.png';
import iconeAF from '../assets/icones/Icon_Antifungigrama.png';
import iconeMC from '../assets/icones/Icon_CulturaMicobacterias.png';

const exames = [
  {
    image: iconeHP,
    imgalt: 'icone de um microscópio',
    title: 'Histopatológico',
    text: `Exame microscópico de amostras de tecidos corporais (fragmentos ou ressecções
        parciais ou totais), retirados por biópsia, cirurgia, punção ou outro tipo de coleta, para
        detecção e análise de possíveis alterações ou lesões. Objetiva confirmar um
        diagnóstico ou hipótese diagnóstica.`,
  },
  {
    image: iconeHQ,
    imgalt: 'icone de um cromossomo',
    title: 'Imuno-histoquímica',
    text: `Exame complementar ao histopatológico, que permite a detecção de antígenos
        específicos e imunofenotipagem de tecidos ou agentes infecciosos. Amplamente
        utilizado na avaliação diagnóstica e prognóstica de pacientes, especialmente em
        tratamentos oncológicos.`,
  },
  {
    image: iconeCH,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Colorações histoquímicas especiais',
    text: `Técnicas de coloração e procedimentos histoquímicos alternativos à Hematoxilina e
        Eosina (H&E), que permitem identificar rapidamente os componentes de um tecido, por
        meio de microscopia óptica.`,
  },
  {
    image: iconeIF,
    imgalt: 'icone de uma molécula',
    title: 'Imunofluorescência para biópsia renal e cutânea',
    text: `Exame realizado em material não fixado (a fresco) para demonstrar a presença de
        imunoglobulinas, auxiliando no diagnóstico e classificação de lesões como
        glomerulopatias e doenças bolhosas da pele.`,
  },
  {
    image: iconeCG,
    imgalt: 'icone de células',
    title: 'Citologia geral',
    text: `Avaliação das células de determinada região do corpo ou fluido, a fim de identificar a
        presença de sinais de inflamação, infecção, sangramentos ou de câncer por meio da
        observação da amostra no microscópio.`,
  },
  {
    image: iconePC,
    imgalt: 'icone de um canal uterino',
    title: 'Citologia cérvico-vaginal',
    text: `Análise de células do colo do útero,  a fim de identificar a presença de sinais de inflamação, infecção, sangramentos ou de câncer por meio da observação da amostra no microscópio, sendo hoje o método mais eficaz para a detecção
        precoce do câncer ginecológico.`,
  },
  {
    image: iconeHP,
    imgalt: 'icone de um microscópio',
    title: 'Microscopia Eletrônica',
    text: `Exame útil para o diagnóstico de algumas condições geralmente raras, que não podem ser definidas
    apenas com a análise histológica convencional ou com imuno-histoquímica/imunofluorescência. Apresenta enorme aplicação na Patologia Renal e é um método fornecido pelo Laboratótio PAS através de parceria com o Hrim – Hospital do Rim em São Paulo.`,
  },
  {
    image: iconeIF,
    imgalt: 'icone de uma molécula',
    title: 'Hibridização in situ',
    text: `Consiste numa técnica onde se utiliza sondas de DNA com sequências específicas para pesquisa de segmentos gênicos
    de agentes etiológicos, geralmente virais, dentre outras situações. É um método com boa especificidade e sensibilidade,
    sendo fornecido pelo Laboratótio PAS através de laboratórios parceiros.`,
  },
  {
    image: iconeMC,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Biologia Molecular',
    text: `Conjunto de técnicas que incluem o estudo do material genético das células, incluindo sequenciamento
    genético e pesquisa de mutações. Tais exames são fornecidos pelo Laboratório PAS através de laboratórios parceiros.`,
  },
  {
    image: iconeEIC,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Exame intraoperatório por congelação',
    text: `Exame realizado durante o momento cirúrgico. A amostra retirada ao longo da cirurgia é congelada
    e cortada em micrótomo de congelação (criostato).`,
  },
  {
    image: iconeRL,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Revisão de lâminas',
    text: `Segunda opinião médica em material já processado e avaliado em
    outro laboratório ou material previamente avaliado no próprio laboratório PAS.`,
  },
  {
    image: iconeCL,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Coleta e coloração Tzanck',
    text: `A citologia de Tzanck consiste em método simples e confiável para o
    diagnóstico de dermatoses virais e parasitárias, a partir de esfregaço cutâneo ou de mucosa.`,
  },
  {
    image: iconeBA,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Baciloscopia para micobactérias atípicas',
    text: `Método microbiológico que envolve a visualização direta ou a
    multiplicação em ambiente controlado de micro-organismos, para
    fins de determinação do gênero/espécie e, quando necessário, sua
    sensibilidade a drogas específicas.
    `,
  },
  {
    image: iconeMC,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Cultura para micobactérias atípicas',
    text: `Método microbiológico que envolve a visualização direta ou a
    multiplicação em ambiente controlado de micro-organismos, para
    fins de determinação do gênero/espécie e, quando necessário, sua
    sensibilidade a drogas específicas.
    `,
  },
  {
    image: iconePM,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Pesquisa microscópica direta para protozoários',
    text: `Método microbiológico que envolve a visualização direta ou a
    multiplicação em ambiente controlado de micro-organismos
    fúngicos, para fins de determinação do gênero/espécie e, quando
    necessário, sua sensibilidade a drogas específicas.
    `,
  },
  {
    image: iconePF,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Pesquisa microscópica direta para fungos',
    text: `Método microbiológico que envolve a visualização direta ou a
    multiplicação em ambiente controlado de micro-organismos
    fúngicos, para fins de determinação do gênero/espécie e, quando
    necessário, sua sensibilidade a drogas específicas.
    `,
  },
  {
    image: iconeCF,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Cultura para fungos',
    text: `Método microbiológico que envolve a visualização direta ou a
    multiplicação em ambiente controlado de micro-organismos
    fúngicos, para fins de determinação do gênero/espécie e, quando
    necessário, sua sensibilidade a drogas específicas.
    `,
  },
  {
    image: iconeAF,
    imgalt: 'icone de uma célula com uma lupa',
    title: 'Antifungigrama',
    text: `Método microbiológico que envolve a visualização direta ou a
    multiplicação em ambiente controlado de micro-organismos
    fúngicos, para fins de determinação do gênero/espécie e, quando
    necessário, sua sensibilidade a drogas específicas.
    `,
  },
];

export default exames;

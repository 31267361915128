import danielatakano from '../assets/medicos/danielatakano.jpg';
import luizantonio from '../assets/medicos/luizantonio.jpg';
import christianetine from '../assets/medicos/christianetine.jpg';
import erikamaranhao from '../assets/medicos/erikamaranhao.jpg';
import fernandalima from '../assets/medicos/fernandalima.jpg';
import isispereira from '../assets/medicos/isispereira.jpg';
import laisprincipe from '../assets/medicos/laisprincipe.jpg';
import rossana from '../assets/medicos/rossana.jpg';
import reginaldo from '../assets/medicos/reginaldo.jpg';
import vanessacoutinho from '../assets/medicos/vanessacoutinho.jpg';
import josericardo from '../assets/medicos/josericardo.jpg';

const medicos = [
  {
    image: danielatakano,
    doctor: 'Dra. Daniela Takano',
    title: 'Anatomopatologista / Sócia-diretora / Responsável Técnica - Sede Recife / CRM PE 15922 / RQE 263',
    caption: 'Atuação em Patologia Cirúrgica; Dermatopatologia; Patologia Oral; Patologia Ocular; Patologia de  Partes Moles.',
    text: `.Graduação em Medicina pela Universidade Federal de São Paulo (Unifesp).
    .Residência Médica em Anatomia-Patológica pela Universidade Federal de São Paulo (Unifesp).
    .Especialista em Patologia pela Sociedade Brasileira de Patologia (SBP) e Associação Médico Brasileira (AMB).
    .Título de Especialista em Dermatopatologia pela International Society of Dermatopathology (ISDP).
    .Mestrado em Patologia pela Universidade Federal de Pernambuco (UFPE).
    .Patologista do Hospital das Clínicas - HC da Universidade Federal de Pernambuco (UFPE).
    .Dermatopatologista do Serviço de Dermatologia do Hospital das Clínicas - HC da Universidade Federal de Pernambuco (UFPE).
    .Membro Titular da Sociedade Brasileira de Patologia (SBP).
    .Membro Titular da International Academy of Pathology (IAP).`,
  },
  {
    image: josericardo,
    doctor: 'Dr. José Ricardo de A. Lima',
    title: 'Anatomopatologista / Sócio-diretor / Responsável Técnico - Arcoverde / CRM PE 12884 / RQE 2580',
    caption: 'Atuação em Patologia Cirúrgica; Ginecopatologia; Patologia de Cabeça e Pescoço; Patologia do Trato Gastrointestinal; Uropatologia; Citopatologia.',
    text: `.Graduação em Medicina pela Universidade de Pernambuco (UPE).
    .Residência Médica em Anatomia-Patológica pela Universidade Federal de Pernambuco (UFPE).
    .Especialista em Patologia pela Sociedade Brasileira de Patologia (SBP) e Associação Médica Brasileira (AMB).
    .Especialista em Citopatologia pela Sociedade Brasileira de Citopatologia (SBC) e Associação Médica Brasileira (AMB).
    .Especialista em Citologia Ginecológica pela Universidade Federal de Pernambuco (UFPE).
    .Patologista do Serviço de Verificação de Óbitos de Pernambuco (SVO-PE).
    .Membro Titular da Sociedade Brasileira de Patologia (SBP).
    .Membro Titular da International Academy of Pathology (IAP).`,
  },
  {
    image: luizantonio,
    doctor: 'Dr. Luiz Antônio da Fonte',
    title: 'Anatomopatologista / Sócio-diretor / Responsável Técnico - Garanhuns / CRM PE 13534 / RQE 2581',
    caption: 'Atuação em Patologia Cirúrgica; Ginecopatologia; Patologia de Cabeça e Pescoço; Patologia Mamária; Patologia Óssea; Patologia Renal; Patologia do Trato Gastrointestinal; Uropatologia.',
    text: `.Graduação em Medicina pela Universidade de Pernambuco (UPE).
    .Residência Médica em Anatomia-Patológica pelo Instituto Nacional do Câncer (INCA).
    .Especialista em Patologia Renal pela University of North Carolina at Chapel Hill (UNC/USA).
    .Patologista do Hospital Universitário Oswaldo Cruz - HUOC da Universidade de Pernambuco (UPE).
    .Patologista do Hospital do Câncer de Pernambuco (HCP).
    .Patologista do Laboratório Central do Estado de Pernambuco (LACEN).
    .Coordenador do serviço de Patologia Renal do Instituto de Medicina Integral Professor Fernando Figueira (IMIP).
    .Membro Titular da Sociedade Brasileira de Patologia (SBP).
    .Membro Titular da International Academy of Pathology (IAP).`,
  },
  {
    image: laisprincipe,
    doctor: 'Dra. Laís de Santana Príncipe',
    title: 'Anatomopatologista / CRM PE 19699 / Responsável Técnica - Serra Talhada / RQE 3304',
    caption: 'Atuação em Patologia Cirúrgica; Dermatopatologia; Patologia de Cabeça e Pescoço; Patologia Mamária; Patologia Ocular; Patologia Oral; Patologia do Trato Gastrointestinal; Uropatologia.',
    text: `.Graduação em Medicina pela Universidade Federal de Pernambuco (UFPE).
    .Residência Médica em Anatomia-Patológica pela Universidade Federal de São Paulo (Unifesp).
    .Especialista em Patologia pela Associação Médico Brasileira (AMB).
    .Patologista do Hospital Oswaldo Cruz - HUOC da Universidade de Pernambuco (UPE).
    .Patologista do Serviço de Dermatologia do Hospital Universitário Oswaldo Cruz - HUOC da
    Universidade de Pernambuco (UPE).
    .Patologista do Centro de Estudos Dermatológicos do Recife - CEDER do Hospital da Santa Casa de
    Misericórdia do Recife.
    .Patologista do Serviço de Verificação de Óbitos de Pernambuco (SVO-PE).
    .Membro Titular da Sociedade Brasileira de Patologia (SBP).
    .Membro Titular da International Academy of Pathology (IAP).`,
  },
  {
    image: vanessacoutinho,
    doctor: 'Dra. Vanessa de Souza Coutinho',
    title: 'Anatomopatologista / CRM PE 23514 / RQE 10365',
    caption: 'Atuação em Patologia Cirúrgica; Ginecopatologia; Patologia Mamária; Patologia Torácica; Citopatologia.',
    text: `.Graduação em Medicina pela Universidade de Pernambuco (UPE).
    .Residência Médica em Anatomia-Patológica pelo Hospital das Clínicas - HC da Universidade Federal de
    Pernambuco (UFPE).
    .Patologista do Hospital da Restauração (HR).
    .Membro Titular da Sociedade Brasileira de Patologia (SBP).`,
  },
  {
    image: rossana,
    doctor: 'Dra. Rossana Sette',
    title: 'Médica Patologista Clínica / CRM PE 6398',
    caption: 'Atuação em Micologia; Onicologia.',
    text: `.Graduação em Medicina pela Universidade Federal de Pernambuco (UFPE).
    .Especialização em Micologia Geral e Médica pela Universidade Federal de Pernambuco (UFPE).
    .Docente no Colégio Brasileiro de Medicina e Saúde – CBMS no curso de Pós-Graduação Lato Sensu em
    Dermatologia, de 2012 a 2021.
    .Docente em cursos de Micologia e Onicologia para podólogos, em parceria com o Instituto Ítalo
    Ventura, desde 2016.
    .Patologista Clínica em Diagnóstico Micológico no Laboratório PAS desde agosto de 2018.`,
  },
  {
    image: reginaldo,
    doctor: 'Dr. Reginaldo Gonçalves',
    title: 'Micologista / CRBio 46.055/05-D',
    caption: 'Atuação como Consultor Técnico e Científico do Laboratório PAS no diagnóstico laboratorial de doenças infecciosas.',
    text: `.Graduação em Ciências Biológicas pela Universidade Federal de Pernambuco (UFPE).
    .Mestre e Doutor em Biologia de Fungos pela Universidade Federal de Pernambuco (UFPE) com período
    sanduíche na Universidade do Minho, Portugal.
    .Professor Adjunto da Área Acadêmica de Medicina Tropical do Centro de Ciências Médicas da
    Universidade Federal de Pernambuco (UFPE).
    .Micologista da Residência Médica em Dermatologia do Hospital das Clínicas - HC da Universidade
    Federal de Pernambuco (UFPE).
    .Membro Permanente dos Programas de Pós-Graduação em Medicina Tropical e Biologia de Fungos da UFPE.
    .Membro do corpo editorial do periódico científico The Open Microbiology Journal (1874-2858).`,
  },
  {
    image: isispereira,
    doctor: 'Dra. Ísis Pereira',
    title: 'Anatomopatologista / Gerente de Controle da Qualidade / CRM PE 26877',
    caption: 'Atuação em Patologia Cirúrgica; Patologia Torácica; Dermatopatologia.',
    text: `.Graduação em Medicina pela Universidade Federal de Pernambuco (UFPE).
    .Residência Médica em Anatomia-Patológica pelo Instituto de Medicina Integral Professor Fernando
    Figueira (IMIP).
    .Treinamento em Pneumopatologia na Universidade Federal de São Paulo (Unifesp).
    .Treinamento em Dermatopatologia pelo serviço Investigação em Dermatologia - RJ.
    .Curso de Formação de Auditores Externos do Programa de Acreditação e Controle da Qualidade da
    Sociedade Brasileira de Patologia 2022 (PACQ - SBP).
    .Membro Titular da Sociedade Brasileira de Patologia (SBP).`,
  },
  {
    image: christianetine,
    doctor: 'Dra. Christiane Tiné',
    title: 'Anatomopatologista e Citopatologista / CRM PE 13136 / RQE 5324, 5325',
    caption: 'Atuação em Patologia Cirúrgica; Patologia Mamária; Citopatologia.',
    text: `.Graduação em Medicina pela Universidade Federal de Pernambuco (UFPE).
    .Residência Médica em Anatomia-Patológica pelo Hospital das Clínicas - HC da Universidade Federal de
    Pernambuco (UFPE).
    .Patologista e coordenadora do serviço de Patologia do Instituto de Medicina Integral Professor
    Fernando Figueira (IMIP).
    .Preceptora do programa de Residência Médica do Instituto de Medicina Integral Professor Fernando
    Figueira (IMIP).
    .Patologista do Hospital Oswaldo Cruz - HUOC da Universidade de Pernambuco (UPE).
    .Patologista do Hospital Getúlio Vargas (HGV).
    .Membro Titular da Sociedade Brasileira de Patologia (SBP).`,
  },
  {
    image: erikamaranhao,
    doctor: 'Dra. Erika Maranhão',
    title: 'Anatomopatologista / CRM PE 20838 / RQE 3951',
    caption: 'Atuação em Patologia Cirúrgica; Patologia do Trato Gastrointestinal, Fígado, Trato biliar e Pâncreas; Patologia Cardiovascular; Patologia do Transplante (Hepático e Cardíaco).',
    text: `.Graduação em Medicina pela Faculdade Pernambucana de Saúde (FPS).
    .Residência Médica em Anatomia-Patológica pelo Hospital das Clínicas - HC da Universidade Federal de
    Pernambuco (UFPE).
    .Patologista do Instituto de Medicina Integral Professor Fernando Figueira (IMIP).
    .Preceptora do programa de Residência Médica do Instituto de Medicina Integral Professor Fernando
    Figueira (IMIP).
    .Patologista do Serviço de Verificação de Óbitos de Pernambuco (SVO-PE).
    .Membro Titular da Sociedade Brasileira de Patologia (SBP).`,
  },
  {
    image: fernandalima,
    doctor: 'Dra. Fernanda Lima Didjurgeit',
    title: 'Anatomopatologista / CRM PE 21278 / RQE 9890',
    caption: 'Atuação em Patologia Cirúrgica; Neuropatologia; Uropatologia.',
    text: `.Graduação em Medicina pela Universidade Federal de Pernambuco (UFPE).
    .Residência Médica em Anatomia-Patológica pelo Hospital das Clínicas - HC da Universidade Federal de
    Pernambuco (UFPE).
    .Treinamento em Neuropatologia no National Institutes of Health, Bethesda (NIH).
    .Patologista e coordenadora do serviço de Patologia do Hospital da Restauração (HR).
    .Membro Titular da Sociedade Brasileira de Patologia (SBP).`,
  },
];

export default medicos;

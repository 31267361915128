import React from 'react';
import Button from './Button';

// essa prop hasbutton será um booleano para verificar se na pag atual tem botao no banner,
// se sim renderiza o botão nesse proprio componente - OK funcionando essa logica

function Banner({
  image,
  text,
  hasbutton,
}) {
  return (
    <div className="w-full h-52 bg-cover bg-center bg-clip-content bg-opacity-100 relative" style={{ backgroundImage: `url(${image})` }}>
      <h2 className="text-7xl font-bold uppercase text-gray-100 absolute bottom-0 left-0 px-12 py-6 max-[640px]:text-5xl max-[460px]:text-4xl">{text}</h2>
      {hasbutton ? (<Button text="saiba mais" />) : null}
    </div>
  );
}

export default Banner;

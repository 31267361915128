/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import CertModal from '../components/CertModal';

import bg from '../assets/Novos_banner_certificações.jpg';
// import cert17 from '../assets/pdfs/PICQ 2017.pdf';
// import cert18 from '../assets/pdfs/PICQ 2018.pdf';
// import cert19 from '../assets/pdfs/PICQ 2019.pdf';
// import cert21 from '../assets/pdfs/PICQ 2021-2022.pdf';
import imgc1 from '../assets/certificados/PICQ 2017.jpg';
import imgc2 from '../assets/certificados/PICQ 2018.jpg';
import imgc3 from '../assets/certificados/PICQ 2019.jpg';
import imgc4 from '../assets/certificados/PICQ 2021-2022.jpg';

// usar imagens dos certificados e não o pdf
/* <object data={cert17} type="application/pdf" width="100%" height="100%">
  <p>
    Alternative text - include a link
    <a href={cert17}>to the PDF!</a>
  </p>
</object> */

function Certificados() {
  const [modalImage, setModalImage] = useState(null);
  const [open, setOpen] = useState(false);

  function handleClick(currImg) {
    setModalImage(currImg);
    setOpen(true);
  }

  function closeModal() {
    setModalImage(null);
    setOpen(false);
  }

  return (
    <>
      <Navbar />
      <Banner image={bg} text="Certificações" />
      <div className="mx-32 my-12 max-md:mx-10">
        <div className="flex max-md:flex-wrap">
          <button type="button" className="m-5" onClick={() => handleClick(imgc1)}>
            <img src={imgc1} alt="certificado PICQ 2017" />
          </button>
          <button type="button" className="m-5" onClick={() => handleClick(imgc2)}>
            <img src={imgc2} alt="certificado PICQ 2017" />
          </button>
        </div>
        <div className="flex max-md:flex-wrap">
          <button type="button" className="m-5" onClick={() => handleClick(imgc3)}>
            <img src={imgc3} alt="certificado PICQ 2017" />
          </button>
          <button type="button" className="m-5" onClick={() => handleClick(imgc4)}>
            <img src={imgc4} alt="certificado PICQ 2017" />
          </button>
        </div>
      </div>
      {open && <CertModal image={modalImage} onRequestClose={closeModal} isOpen={open} />}
      <Footer />
    </>
  );
}

export default Certificados;

import React from 'react';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Footer from '../components/Footer';

import bg from '../assets/Novos_banner_orientacao.jpg';

import guiacoleta from '../assets/pdfs/MANUAL_COLETA_VERSÃO_EXTERNA.pdf';

function Orientacoes() {
  return (
    <>
      <Navbar />
      <Banner text="Orientações ao paciente" image={bg} />
      <div className="mx-32 max-[875px]:mx-10 max-sm:mx-10 my-12">
        <h2 className="text-lg leading-tight font-semibold text-med-cyan my-6">
          Para um diagnóstico anatomopatológico mais assertivo, é fundamental que o material
          biológico seja manuseado de forma correta, da coleta ao processamento no laboratório.
          O LabPAS adota as melhores práticas laboratoriais em anatomia patológica.
        </h2>
        <p className="text-lg leading-tight font-semibold text-med-cyan my-6">
          Em alguns casos, os próprios pacientes e familiares, são responsáveis pelo
          transporte do material, devendo tomar alguns cuidados
          para manter a integridade da(s) amostra(s).
        </p>
        <ul className="list-decimal ml-5 mb-10 text-base leading-tight font-bold text-dark-cyan">
          <li className="my-1">
            Ao receber o material após a coleta, verifique se as informações sobre o paciente
            e a amostra estão corretas.
          </li>
          <li className="my-1">
            Avaliar se está acondicionado dentro dos padrões a) gerais: frasco transparente,
            rígido, com tampa e líquido fixador, devidamente fechado;
            e b) específicos (conforme tabela).
          </li>
          <li className="my-1">
            Proteger de impactos, não expor à luz solar, nem balançar ou revirar
            o frasco/recipiente, a fim de evitar danos, derrame da amostra e quebra de lâminas
            (quando for o caso).
          </li>
          <li className="my-1">
            Atentar para a unidade de armazenamento recomendada pelo laborátorio:
            temperatura ambiente, geladeira ou freezer.
          </li>
          <li className="my-1">
            Respeitar o tempo de entrega do material (conforme tabela).
          </li>
        </ul>
        <div className="text-lg text-center mb-12 max-sm:p-2 max-sm:overflow-scroll">
          <table className="text-dark-cyan text-xs/tight border-2 border-dark-cyan leading-tight">
            <thead className="border-2 border-dark-cyan bg-dark-cyan text-white uppercase">
              <tr>
                <th colSpan="1" className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">sigla</th>
                <th colSpan="1" className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">tipo de exame</th>
                <th colSpan="1" className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">tipo de amostras</th>
                <th colSpan="1" className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">meio de fixação</th>
                <th colSpan="1" className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">volume do meio de fixação</th>
                <th colSpan="1" className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">tempo para entrega do material</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-2 border-dark-cyan">
                <td className="uppercase p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">hp</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Histopatológico</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">
                  Biópsias, fragmentos
                  <br />
                  e peças pequenas
                </td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Formol 10% tamponado</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">1 volume de amostra para 10 volumes de formol 10% tamponado</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Até 24 horas</td>
              </tr>
              <tr className="border-2 border-dark-cyan bg-med-cyan/10">
                <td className="uppercase p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">hp</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Histopatológico</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">
                  Peças grandes
                </td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Formol 10% tamponado</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">1 volume de amostra para 10 volumes de formol 10% tamponado</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Imediatamente</td>
              </tr>
              <tr className="border-2 border-dark-cyan">
                <td className="uppercase p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">cg</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Citologia geral</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Lâminas (esfregaços)</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Álcool absoluto (95%)</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Até cobrir a lâmina por completo</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Até 24 horas</td>
              </tr>
              <tr className="border-2 border-dark-cyan bg-med-cyan/10">
                <td className="uppercase p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">cg</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Citologia geral</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Líquidos</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">A fresco ou álcool 50% (álcool absoluto:água destilada 1:1)</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Se optado por álcool 50%, utilizar na proporção de 1:1 (1ml de álcool 50% para cada 1ml de amostra líquida)</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Até 6 horas</td>
              </tr>
              <tr className="border-2 border-dark-cyan">
                <td className="uppercase p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">cv</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Citologia cérvico-vaginal</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Lâminas em álcool absoluto ou spray fixador</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Álcool absoluto (95%)</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Até cobrir a lâmina por completo</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Até 24 horas</td>
              </tr>
              <tr className="border-2 border-dark-cyan bg-med-cyan/10">
                <td className="uppercase p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">if</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Imunoflorescência</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Biópsias em meio de Michel</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Meio de Michel</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Fornecemos recipientes e volumes adequados</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Até 72 horas</td>
              </tr>
              <tr className="border-2 border-dark-cyan">
                <td className="uppercase p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">me</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Microscopia eletrônica</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Biópsias em glutaraldeído 2,5%</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Glutaraldeído 2,5%</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Fornecemos recipientes e volumes adequados</td>
                <td className="p-3 max-md:p-2 max-md:text-sm leading-tight text-base border-l border-r border-dark-cyan">Até 72 horas</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-center">
          <a href={guiacoleta} download={guiacoleta} className="border-2 border-dark-cyan rounded-3xl uppercase text-2xl text-center text-dark-cyan p-4 hover:bg-dark-cyan hover:text-white transition-all ease-linear">Baixe aqui o guia de coleta completo</a>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Orientacoes;

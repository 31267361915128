import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function CertModal({ image, isOpen, onRequestClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="rounded-3xl mx-auto my-auto w-1/2 max-h-max lg:w-3/4 max-sm:w-4/5 focus:outline-none"
      contentLabel="Modal Imagem"
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          zIndex: 50,
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
      }}
    >
      <div className="p-10 rounded justify-center items-center relative">
        <button
          type="button"
          onClick={onRequestClose}
          className="bg-med-cyan font-semibold text-gray-50 absolute right-4 top-4 py-2 px-4 rounded-2xl hover:bg-gray-50 hover:text-dark-blue"
        >
          x
        </button>
        <img src={image} alt="certificado picq" />
      </div>
    </Modal>
  );
}

export default CertModal;

// essa função formata o texto de cada medico no doc PAS EQUIPE MEDICA
// para ser usado como varios itens de lista
function convertli(text) {
  const arr = text.split('.');
  const FormattedArray = arr.filter((item) => item.trim() !== '' && item.trim() !== '\n' && item.trim() !== '\n  ');
  // console.log('formatted arr', FormattedArray);
  return FormattedArray;
}

export default convertli;

import React from 'react';
import CardExam from '../components/CardExam';
import Banner from '../components/Banner';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import exames from '../data/exames';
import bg from '../assets/Novos_banner_exames.jpg';
import vetor from '../assets/arabesco_2.png';

function Exames() {
  return (
    <>
      <Navbar id="top" />
      <Banner image={bg} text="exames" hasbutton={false} />
      <div id="conteudo" className="flex flex-col my-12 px-32 max-sm:px-10 relative overflow-x-clip min-[1600px]:px-60">
        <img src={vetor} alt="imagem decorativa" className="absolute -top-48 -right-24 rotate-180 h-60 w-72 -z-10" />
        {exames.map((exame) => (<div key={exame.title}><CardExam data={exame} /></div>))}
      </div>
      <Footer />
    </>
  );
}

export default Exames;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Termos from './pages/Termos';
import Medicos from './pages/Medicos';
import QuemSomos from './pages/Quemsomos';
import Exames from './pages/Exames';
import Convenios from './pages/Convenios';
import Contato from './pages/Contato';
import Faq from './pages/Faq';
import Login from './pages/Login';
import Certificados from './pages/Certificados';
import Orientacoes from './pages/Orientacoes';

function Routers() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/quemsomos" element={<QuemSomos />} />
      <Route path="/exames" element={<Exames />} />
      <Route path="/nossosmedicos" element={<Medicos />} />
      <Route path="/convenios" element={<Convenios />} />
      <Route path="/contato" element={<Contato />} />
      <Route path="/perguntasfrequentes" element={<Faq />} />
      <Route path="/login" element={<Login />} />
      <Route path="/certificados" element={<Certificados />} />
      <Route path="/orientacoes" element={<Orientacoes />} />
      <Route path="/termos-uso-privacidade" element={<Termos />} />
    </Routes>
  );
}

export default Routers;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function InfoModal({ information }) {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const navigate = useNavigate();

  function closeModal() {
    setModalIsOpen(false);
  }

  function saibaMais(e) {
    e.preventDefault();
    navigate('/orientacoes');
  }

  return (
    <Modal
      isOpen={modalIsOpen}
        // eslint-disable-next-line react/jsx-no-bind
      onRequestClose={closeModal}
      className="rounded-3xl mx-auto my-auto w-1/2 max-h-max overflow-auto opacity-90 lg:w-1/3 max-sm:w-4/5 focus:outline-none"
      contentLabel="Modal Orientações"
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          zIndex: 50,
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
      }}
    >
      <div className="bg-dark-blue p-10 rounded justify-center items-center relative">
        <button
          type="button"
          onClick={closeModal}
          className="bg-med-cyan font-semibold text-gray-50 absolute right-4 top-4 py-2 px-4 rounded-2xl hover:bg-gray-50 hover:text-dark-blue"
        >
          x
        </button>
        <h2 className="text-2xl text-gray-50 font-bold uppercase text-center max-sm:text-base">
          orientações
          <br />
          da coleta
        </h2>
        <p className="text-gray-50 text-sm font-semibold py-2 px-8 my-4 text-justify max-sm:text-left max-sm:text-xs">{information}</p>
        <button
          type="button"
          onClick={saibaMais}
          className="bg-med-cyan hover:bg-gray-50 hover:text-dark-blue opacity-100 text-white uppercase font-bold py-2 px-4 rounded-2xl mx-auto block"
        >
          saiba mais
        </button>
      </div>
    </Modal>
  );
}

export default InfoModal;

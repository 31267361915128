import React from 'react';
import CardDoctor from '../components/CardDoctor';
import Banner from '../components/Banner';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import medicos from '../data/doctors';
import bg from '../assets/Novos_banner_medicos.jpg';

function Medicos() {
  return (
    <>
      <Navbar />
      <Banner text="nossos médicos" image={bg} />
      <div className="mx-20 max-sm:mx-5 mt-4 mb-10">
        <p className="px-12 max-sm:px-4 py-4 text-dark-blue text-base/tight font-semibold">
          Contamos com uma equipe com expertise em diversas áreas da patologia,
          altamente capacitada, constantemente atualizada e próxima tanto do paciente,
          quanto dos médicos assistentes. Nossos patologistas são comprometidos com a
          qualidade e agilidade do diagnóstico, conscientes da importância do laudo
          anatomopatológico para a condução terapêutica dos pacientes.
        </p>
        {medicos.map((medico) => (<CardDoctor data={medico} key={medico.doctor} />))}
      </div>
      <Footer />
    </>
  );
}

export default Medicos;

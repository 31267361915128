/* eslint-disable linebreak-style */
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import Routers from './Routes';

function App() {
  return (
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
  );
}

export default App;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
// import Button from './Button';

function CardExam(props) {
  const {
    image, title, text,
  } = props.data;

  return (
    <div className="flex items-center justify-end py-2 max-sm:my-4 max-[820px]:flex-wrap max-[820px]:w-full max-[820px]:text-center max-[820px]:justify-center">
      <img src={image} alt="icone ilustrativo" className="h-32 py-2" />
      <div className="text-justify px-4 max-sm:text-center max-sm:py-5">
        <h2 className="text-med-cyan text-2xl font-bold px-1 max-[820px]:text-center">{title}</h2>
        <p className="px-1 text-dark-blue font-medium text-base leading-tight">{text}</p>
      </div>
      <div className="bg-med-cyan rounded-3xl px-4 py-4 justify-items-end">
        <a href="https://api.whatsapp.com/send/?phone=558188949182&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer" className="py-2 px-4 bg-med-cyan font-semibold uppercase text-gray-100 text-xl rounded-2xl whitespace-nowrap hover:text-dark-blue transition-all ease-in">
          saiba mais
        </a>
      </div>
    </div>
  );
}

export default CardExam;

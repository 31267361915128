/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { useLocation, Link } from 'react-router-dom';
import Button from './Button';
import logo from '../assets/logo_menu.png';

import './styles.css';

// aqui nao esquecer de fazer a logica para deixar marcada a pagina que o usuario está
// - ok funcionando

// aqui falta: - tirar as linhas de contrução - ok
//             - colocar os links e uma animação nas redes sociais - ok
//             - mudar logo para versão correta - ok

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <nav className="bg-dark-blue h-24 flex w-full text-gray-100 max-[820px]:h-fit">
        <div className="flex mx-5 p-2 w-full max-[820px]:flex-wrap max-md:hidden">
          <div className="flex items-center">
            <Link to="/" alt="Home"><img src={logo} alt="Logo Labpas" className="max-h-20 min-w-max p-1 mx-1" /></Link>
          </div>
          <ul className="flex-auto flex items-center justify-end font-bold max-[1100px]:flex-wrap">
            <li className="px-2">
              <a href="/quemsomos" alt="Quem somos" className={` hover:text-med-cyan transition-all ease-in active:border-b-2 border-med-cyan ${currentPath === '/quemsomos' ? ' border-b-2 border-med-cyan' : ''}`}>QUEM SOMOS</a>
            </li>
            <li className="px-2">
              <a href="/exames" alt="Exames" className={` hover:text-med-cyan transition-all ease-in active:border-b-2 border-med-cyan ${currentPath === '/exames' ? ' border-b-2 border-med-cyan' : ''}`}>EXAMES</a>
            </li>
            <li className="px-2">
              <a href="/nossosmedicos" alt="Nossos médicos" className={` hover:text-med-cyan transition-all ease-in active:border-b-2 border-med-cyan ${currentPath === '/nossosmedicos' ? ' border-b-2 border-med-cyan' : ''}`}>NOSSOS MÉDICOS</a>
            </li>
            <li className="px-2">
              <a href="/convenios" alt="Convênios" className={` hover:text-med-cyan transition-all ease-in active:border-b-2 border-med-cyan ${currentPath === '/convenios' ? ' border-b-2 border-med-cyan' : ''}`}>CONVÊNIOS</a>
            </li>
            <li className="px-2">
              <Link to="/contato" alt="Contato" className={` hover:text-med-cyan transition-all ease-in active:border-b-2 border-med-cyan ${currentPath === '/contato' ? ' border-b-2 border-med-cyan' : ''}`}>CONTATO</Link>
            </li>
          </ul>
          <div className="p-2 my-auto">
            <Button text="Login" link="/login" alt="Login" />
          </div>
          <div alt="Redes sociais" className="flex w-fit px-2 py-2 items-center justify-end">
            <div className="bg-white rounded-full h-7 mx-1 opacity-80 hover:scale-125 transition-all ease-linear">
              <a href="https://www.facebook.com/profile.php?id=100094108053177">
                <Icon icon="ri:facebook-fill" width="28" height="28" className="p-1" style={{ color: '#00679A' }} />
              </a>
            </div>
            <div className="bg-white rounded-full h-7 mx-1 opacity-80 hover:scale-125 transition-all ease-linear">
              <a href="https://www.instagram.com/laboratoriopas/">
                <Icon icon="mdi:instagram" width="28" height="28" className="p-1 cursor-pointer" style={{ color: '#00679A' }} />
              </a>
            </div>
            {/* <div className="bg-white rounded-full h-7 mx-1 opacity-80">
              <Icon icon="mdi:twitter" color="white" width="28" height="28" className="p-1" style={{ color: '#00679A' }} />
            </div>
            <div className="bg-white rounded-full h-7 mx-1 opacity-80">
              <Icon icon="mdi:youtube" color="white" width="28" height="28" className="p-1" style={{ color: '#00679A' }} />
            </div> */}
            <div className="bg-white rounded-full h-7 mx-1 opacity-80 hover:scale-125 transition-all ease-linear">
              <a href="https://api.whatsapp.com/send/?phone=558188949182&text&type=phone_number&app_absent=0">
                <Icon icon="mdi:whatsapp" color="white" width="28" height="28" className="p-1" style={{ color: '#00679A' }} />
              </a>
            </div>
          </div>
        </div>
        <div className="md:hidden flex justify-between w-full px-5 py-2">
          <div className="flex items-center">
            <Link to="/" alt="Home"><img src={logo} alt="Logo Labpas" className="max-h-16 min-w-max p-1 mx-1" /></Link>
          </div>
          <div className="flex">
            <div className="p-2 my-auto scale-90">
              <Button text="Login" link="/login" alt="Login" />
            </div>
            <button type="button" onClick={() => setIsOpen(!isOpen)} className=" py-2">
              <Icon icon="line-md:chevron-down-circle" width={40} height={40} className={`${isOpen ? 'rotate-180' : ''} transition-all ease-linear`} />
            </button>
          </div>
        </div>
      </nav>
      <div className={`nav-container ${isOpen ? 'open' : 'close'} md:hidden bg-dark-blue text-white flex flex-col relative z-50 w-full text-center text-sm px-5`}>
        {/* <button type="button" onClick={() => setIsOpen(false)} className="font-black text-sm absolute top-2 right-4">X</button> */}
        <ul className="flex-1 flex-col flex items-center justify-end font-bold my-1">
          <li className="my-1">
            <a href="/quemsomos" alt="Quem somos" className={` hover:text-med-cyan transition-all ease-in active:border-b-2 border-med-cyan ${currentPath === '/quemsomos' ? ' border-b-2 border-med-cyan' : ''}`}>QUEM SOMOS</a>
          </li>
          <li className="my-1">
            <a href="/exames" alt="Exames" className={` hover:text-med-cyan transition-all ease-in active:border-b-2 border-med-cyan ${currentPath === '/exames' ? ' border-b-2 border-med-cyan' : ''}`}>EXAMES</a>
          </li>
          <li className="my-1">
            <a href="/nossosmedicos" alt="Nossos médicos" className={` hover:text-med-cyan transition-all ease-in active:border-b-2 border-med-cyan ${currentPath === '/nossosmedicos' ? ' border-b-2 border-med-cyan' : ''}`}>NOSSOS MÉDICOS</a>
          </li>
          <li className="my-1">
            <a href="/convenios" alt="Convênios" className={` hover:text-med-cyan transition-all ease-in active:border-b-2 border-med-cyan ${currentPath === '/convenios' ? ' border-b-2 border-med-cyan' : ''}`}>CONVÊNIOS</a>
          </li>
          <li className="my-1">
            <a href="/contato" alt="Contato" className={` hover:text-med-cyan transition-all ease-in active:border-b-2 border-med-cyan ${currentPath === '/contato' ? ' border-b-2 border-med-cyan' : ''}`}>CONTATO</a>
          </li>
        </ul>
        <div alt="Redes sociais" className="flex-1 flex px-2 py-2 items-center justify-center w-full">
          <div className="bg-white rounded-full h-7 mx-1 opacity-80 hover:scale-125 transition-all ease-linear">
            <a href="https://www.facebook.com/profile.php?id=100094108053177">
              <Icon icon="ri:facebook-fill" width="28" height="28" className="p-1" style={{ color: '#00679A' }} />
            </a>
          </div>
          <div className="bg-white rounded-full h-7 mx-1 opacity-80 hover:scale-125 transition-all ease-linear">
            <a href="https://www.instagram.com/laboratoriopas/">
              <Icon icon="mdi:instagram" width="28" height="28" className="p-1 cursor-pointer" style={{ color: '#00679A' }} />
            </a>
          </div>
          {/* <div className="bg-white rounded-full h-7 mx-1 opacity-80">
              <Icon icon="mdi:twitter" color="white" width="28" height="28" className="p-1" style={{ color: '#00679A' }} />
            </div>
            <div className="bg-white rounded-full h-7 mx-1 opacity-80">
              <Icon icon="mdi:youtube" color="white" width="28" height="28" className="p-1" style={{ color: '#00679A' }} />
            </div> */}
          <div className="bg-white rounded-full h-7 mx-1 opacity-80 hover:scale-125 transition-all ease-linear">
            <a href="https://api.whatsapp.com/send/?phone=558188949182&text&type=phone_number&app_absent=0">
              <Icon icon="mdi:whatsapp" color="white" width="28" height="28" className="p-1" style={{ color: '#00679A' }} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;

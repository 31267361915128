/* eslint-disable max-len */
import iconeHP from '../assets/icones/Icon_Histopatologico.png';
import iconeHQ from '../assets/icones/Icon_ImunoHisto.png';
import iconeCH from '../assets/icones/Icon_Colorações.png';
import iconeIF from '../assets/icones/Icon_Imunoflorescencia.png';
import iconeCG from '../assets/icones/Icon_Citopatologia.png';
import iconePC from '../assets/icones/Icon_CitoCervico.png';
import iconeEIC from '../assets/Icon_ExameIntraCongelação.png';
import iconeRL from '../assets/icones/Icon_Revisão Laminas.png';
import iconeCL from '../assets/icones/Icon_ColetaTzack.png';
import iconeBA from '../assets/icones/Icon_BaciloscopiaAtipica.png';
import iconePM from '../assets/icones/Icon_PesquisaMicroscopicaDiretaProtozoarios.png';
import iconePF from '../assets/icones/Icon_PesquisaMicroscopicaDiretaFungos.png';
import iconeCF from '../assets/icones/Icon_Culturafungo.png';
import iconeAF from '../assets/icones/Icon_Antifungigrama.png';
import iconeMC from '../assets/icones/Icon_CulturaMicobacterias.png';

const carouselData = [
  {
    title: 'exame intraoperatório por congelação',
    description: '',
    icon: iconeEIC,
    id: '0',
  },
  {
    title: 'baciloscopia para micobactérias atípicas',
    description: '',
    icon: iconeBA,
    id: '1',
  },
  {
    title: 'revisão de lâminas',
    description: '',
    icon: iconeRL,
    id: '2',
  },
  {
    title: 'cultura para fungos',
    description: '',
    icon: iconeCF,
    id: '3',
  },
  {
    title: 'cultura para micobactérias atípicas',
    description: '',
    icon: iconeMC,
    id: '4',
  },
  {
    title: 'antifungigrama',
    description: '',
    icon: iconeAF,
    id: '5',
  },
  {
    title: 'pesquisa microscópica direta para fungos',
    description: '',
    icon: iconePF,
    id: '6',
  },
  {
    title: 'pesquisa microscópica direta para protozoários',
    description: '',
    icon: iconePM,
    id: '7',
  },
  {
    title: 'coleta e coloração Tzanck',
    description: '',
    icon: iconeCL,
    id: '8',
  },
  {
    title: 'imuno-histoquímica',
    description: '',
    icon: iconeHQ,
    id: '9',
  },
  {
    title: 'imunofluorescência',
    description: '',
    icon: iconeIF,
    id: '10',
  },
  {
    title: 'citologia geral',
    description: '',
    icon: iconeCG,
    id: '11',
  },
  {
    title: 'citologia cérvico-vaginal',
    description: '',
    icon: iconePC,
    id: '12',
  },
  {
    title: 'histopatológico',
    description: '',
    icon: iconeHP,
    id: '13',
  },
  {
    title: 'colorações histoquímicas especiais',
    description: '',
    icon: iconeCH,
    id: '14',
  },
];

export default carouselData;

/* eslint-disable */
import React from "react";
// import Medicos from './Medicos';
// import CardDoctor from '../components/CardDoctor';
// import convertli from '../utils/convertli';
// import medicos from '../data/doctors';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import termos from '../assets/pdfs/TERMO_DE_USO_-_PAS_-_RETIFICADO.pdf';
import politica from '../assets/pdfs/POLÍTICA_DE_PRIVACIDADE_-_PAS_-_RETIFICADO.pdf';

function Tests() {
  return (
    <>
      <Navbar />
      <div className="mx-10 sm:mx-20 lg:mx-32 my-12">
        <h2 className="uppercase font-bold text-center mb-8 text-xl text-dark-blue">termos de uso</h2>
        <p>Os serviços do site são fornecidos pelo PAS Patologistas Associados Ltda,
          titular da propriedade intelectual sobre o website, conteúdos e demais ativos
          relacionados à plataforma.</p>
        <br />
        <h3 className="font-semibold">DO OBJETO</h3>
        <p>O presente instrumento tem como objetivo expor as regras para utilização
          da plataforma virtual do site http://www.labpas.com.br/.</p>
        <br />
        <h3 className="font-semibold">DA ACEITAÇÃO</h3>
        <p>Os presentes Termos de Uso estabelecem obrigações contratadas de livre
          e espontânea vontade entre a plataforma e as pessoas físicas ou jurídicas
          usuárias do nosso site.
          É importante dizer que ao utilizar nossa plataforma você está aceitando
          integralmente as normas constantes nestes termos e se compromete a respeitá-
          las, sob pena de sofrer as penalidades cabíveis.
          A aceitação dos termos deste instrumento é essencial para o acesso e
          utilização dos serviços oferecidos no site por nós do laboratório PAS -
          Patologistas Associados. Caso você não concorde com algo, você, infelizmente,
          deverá se abster de utilizar nossa plataforma, para evitar eventuais transtornos.</p>
        <br />
        <h3 className="font-semibold">DO ACESSO DOS USUÁRIOS</h3>
        <p>Serão utilizadas todas as técnicas a nossa disposição para permitir o seu
          acesso ao nosso site 24 horas por dia, 7 dias por semana, de modo que você
          poderá acessar o conteúdo disponibilizado em nosso site ou nos contatar através
          do nosso e-mail sempre que quiser.
          Todavia, é possível que o acesso à página seja interrompido, limitado ou
          suspenso para que realizemos atualizações, modificações ou qualquer ação
          necessária ao seu bom funcionamento.</p>
        <br />
        <h3 className="font-semibold">DOS SERVIÇOS OFERTADOS</h3>
        <p>Em nossa plataforma você poderá conhecer um pouco mais sobre: Quem
          somos; nossas certificações; nossos exames; nossos médicos; nossos
          diferenciais e os planos aos quais somos conveniados.
          Aos clicar na aba “login”, você poderá se identificar como médico ou
          paciente, na qual, ao preencher o número do usuário (no caso do médico) e
          número do exame (no caso do paciente) e informar a senha de acesso pessoal,
          você será redirecionado ao sistema Pathoweb e poderá consultar as
          informações e documentos desejados.</p>
        <br />
        <h3 className="font-semibold">DO SUPORTE</h3>
        <p>Em caso de qualquer dúvida, sugestão ou problema com a utilização da
          plataforma, você poderá entrar em contato conosco através de nosso e-mail:
          privacidade@labpas.com.br.
          Nós temos compromisso com você e, assim, responderemos o mais
          rápido possível para que você continue a acessar o nosso site sem quaisquer
          intercorrências.</p>
        <br />
        <h3 className="font-semibold">DAS RESPONSABILIDADES</h3>
        <ul className="list-disc">
          É de sua responsabilidade enquanto usuário do nosso site:
          <li className="ml-8">A veracidade e exatidão dos dados informados;</li>
          <li className="ml-8">Utilizar o site e seus serviços de forma adequada e diligente;</li>
          <li className="ml-8">Não utilizar o site com objetivo ou como meio para prática de atos ilícitos,
            proibidos pela lei e pelos Termos de Uso, lesivos aos direitos e interesses
            de terceiros;</li>
          <li className="ml-8">Se abster de utilizar o site de modo que possa danificar, inutilizar ou
            sobrecarregar a plataforma e seus serviços, bem como equipamentos de
            informática (hardware e software) de outros usuários, assim como
            documentos, arquivos e toda classe de conteúdo armazenados nos
            respectivos equipamentos;</li>
          <li className="ml-8">Não utilizar o site de modo a impedir a normal utilização ou gozo deste e
            de seus serviços por parte dos demais usuários;</li>
          <li className="ml-8">Os defeitos ou vícios técnicos originados no seu próprio sistema;</li>
          <li className="ml-8">A correta utilização da plataforma e dos serviços ofertados, prezando
            sempre pela boa convivência, pelo respeito e cordialidade;</li>
          <li className="ml-8">A observância destes Termos de Uso, bem como do que dispõe a nossa
            Política de Privacidade e as legislações nacional e internacional.
            É de nossa responsabilidade:</li>
          <li className="ml-8">Verificar as necessidades e peculiaridades de seu contato e, por
            conseguinte, atendê-lo(a) da melhor forma;</li>
          <li className="ml-8">Os conteúdos ou atividades ilícitas praticadas através de nossa
            plataforma;</li>
          <li className="ml-8">Informar-lhes sobre eventuais incidentes de segurança em nosso site.</li>
        </ul>
        <p>É importante alertar que não nos responsabilizamos por links externos
          contidos em seu sistema que possam te redirecionar a ambiente externo a sua
          rede.
          Não serão incluídos links externos ou páginas que sirvam para fins com
          quaisquer informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas,
          discriminatórias ou ofensivas.
          Este instrumento de “Termos de Uso” não cede ou transfere a você
          qualquer direito, de modo que o acesso e ciência de seu conteúdo não lhe
          concede qualquer direito de propriedade intelectual.</p>
        <br />
        <h3 className="font-semibold">DAS SANÇÕES</h3>
        <p>Sem prejuízo das demais medidas legais cabíveis, nós poderemos, a
          qualquer momento, advertir-lhe em caso de uso indevido de nossa plataforma, o
          qual pode se caracterizar como a violação de qualquer dispositivo do presente
          termo; o descumprimento dos seus deveres enquanto usuário e; o
          comportamento doloso, fraudulento e ofensivo.</p>
        <br />
        <h3 className="font-semibold">DAS ALTERAÇÕES</h3>
        <p>Os itens descritos nestes termos poderão sofrer alterações,
          unilateralmente e a qualquer tempo, para fins de adequação a futuras exigências
          legais, bem como para que possamos melhorar a sua experiência.
          Se as alterações feitas por nós forem consideradas substanciais por
          nossa equipe, nós nos comprometemos a informar-lhe acerca das mudanças
          realizadas através de banner em nosso site.
          Você tem a decisão de aceitar as nossas alterações ou não e, em caso
          negativo, aconselhamos-lhe a parar de acessar a nossa plataforma. Porém, você
          sabe que sempre poderá contar conosco para sanar eventuais dúvidas também
          a respeito dessas alterações, bem como sabe que estamos sempre abertos ao
          diálogo.</p>
        <br />
        <h3 className="font-semibold">DA POLÍTICA DE PRIVACIDADE</h3>
        <p>Nós respeitamos a sua privacidade e prezamos pela proteção dos seus
          dados pessoais e dados pessoais sensíveis. Portanto, temos uma Política de
          Privacidade em vigor, a qual pode ser consultada por você a qualquer momento
          em nossa plataforma.</p>
        <br />
        <h3 className="font-semibold">DO FORO</h3>
        <p>Para a solução de controvérsias decorrentes do presente instrumento de
          Termos de Uso serão aplicadas as normas vigentes na República Federativa do
          Brasil e os eventuais litígios deverão ser apresentados no foro da comarca de
          Recife/PE.
          Quaisquer sugestões ou perguntas sobre estes termos de uso do site
          <a href="http://www.labpas.com.br/"> http://www.labpas.com.br/</a>, é só enviar um e-mail para o endereço
          privacidade@labpas.com.br que nossa data protection officer (encarregada de
          dados) terá o maior prazer em atendê-lo(a).
        </p>
        <br />
        <h4 className="font-semibold">Em vigor desde: 12 de junho de 2023.</h4>
      </div>
      <div className="mx-10 sm:mx-20 lg:mx-32 my-12">
        <h2 className="uppercase font-bold text-center mb-8 text-xl text-dark-blue">política de privacidade</h2>
        <p>Nós do PAS Patologistas Associados respeitamos a sua privacidade
          e prezamos pela proteção dos seus dados pessoais!</p>
        <br />
        <p>A nossa Política de Privacidade está voltada para permitir que você tenha
          total conhecimento de quais dados são coletados, como os utilizamos e,
          sobretudo, com a finalidade de permitir que você tenha total controle sobre
          eles, em respeito a você, nosso paciente, parceiro ou usuário do nosso site.</p>
        <br />
        <p>Nós prezamos pela transparência em nossa plataforma, de modo que
          no tratamento de seus dados não poderia ser diferente.</p>
        <br />
        <p>Assim, o objetivo desta Política de Privacidade é justamente muni-lo(a)
          de todas as informações necessárias para que você tenha ciência e a
          oportunidade de decidir sobre quais informações deseja compartilhar conosco,
          em atenção a sua autodeterminação informativa.</p>
        <h3 className="font-semibold">1. Informações que você compartilha conosco</h3>
        <p>Nós do PAS Patologistas Associados inevitavelmente precisaremos de
          alguns dados pessoais para que possamos realizar o seu atendimento em nosso
          laboratório, tais como: Nome completo; CPF; RG; Endereço; Contato
          telefônico/whatsapp; e-mail e, em atendimentos por plano de saúde, informações
          do convênio.
          Adicionalmente, ainda poderão ser recolhidos dados pessoais sensíveis,
          em especial relacionados à sua saúde, a depender do atendimento ou exame a
          ser feito.</p>
        <br />
        <p>Vale ressaltar que apenas coletamos os dados estritamente
          necessários ao cumprimento das finalidades mencionadas, em respeito ao
          Princípio da Necessidade (art. 6º, III, da LGPD).
          É importante alertar ainda que coletamos cookies. Os cookies permitem a
          coleta de dados relacionados à navegação dependendo do tipo de dispositivo
          usado, das autorizações concedidas por você através das configurações de seu
          dispositivo e das funcionalidades utilizadas em cada aplicação. Podemos utilizar
          cookies próprios ou de terceiros em nosso site.</p>
        <br />
        <h5>E o que são cookies?</h5>
        <p>São pequenos arquivos de texto que podem ou não ser adicionados no
          navegador do dispositivo. Estes arquivos armazenam e reconhecem dados que
          garantem o correto funcionamento dos sites e aplicativos e nos ajudam a
          identificar suas preferências, além de melhorar sua experiência.</p>
        <br />
        <h5>Tipos de cookies e suas finalidades:</h5>
        <br />
        <ul className="list-disc">
          Os cookies podem coletar dados para diferentes finalidades relacionadas
          às funcionalidades de nosso site. Confira a seguir os tipos que utilizamos:
          <li className="ml-8"><strong>Necessários:</strong> São exigidos para o funcionamento do site e não podem
            ser desconsiderados dos nossos sistemas. Geralmente são ativados
            apenas em resposta a ações do usuário como um pedido de serviços
            – por exemplo, configurar suas preferências de privacidade, efetuar
            login ou preencher formulários. Você pode configurar seu navegador
            para bloquear esses cookies ou avisá-lo da existência deles. No
            entanto, algumas funcionalidades do site talvez sejam perdidas.
            Esses cookies não armazenam qualquer tipo de informação pessoal
            que identifique seu titular.</li>
          <li className="ml-8"><strong>Analíticos:</strong> são cookies empregados para coletar informações sobre
            como os visitantes utilizam nosso site, incluindo o número de
            visitantes, os sites que os direcionaram ao nosso website e as páginas
            que visitaram. Tais informações nos ajudam a elaborar relatórios e
            aprimorar nossos websites, permitindo-nos, por exemplo, saber se os
            usuários constataram erros de qualquer espécie e assegurar que
            encontrem facilmente o que estão procurando. Normalmente esses
            cookies permanecem no seu computador até que você os exclua.
          </li>
        </ul>
        <br />
        <h3 className="font-semibold">Posso desativar os cookies e as outras formas de coleta de
          informações?</h3>
        <p>Você pode desativar ou excluir os cookies, bem como demais tecnologias
          de coleta de dados, nas configurações do seu navegador e nas configurações
          do sistema operacional do seu dispositivo, com exceção dos cookies de
          funcionamento (necessários) que, se desativados, não permitirão o uso de sites
          e aplicativos.
          Disponibilizamos a seguir alguns links para as páginas de ajuda e suporte
          dos navegadores mais utilizados, que poderão ser acessados pelo usuário
          interessado em obter mais informações sobre a gestão dos cookies em seu
          navegador:</p>
        <br />
        {/* parei aqui antes dos links do pdf */}
        <h3 className="font-semibold">DAS ALTERAÇÕES</h3>
        <p>Os itens descritos nestes termos poderão sofrer alterações,
          unilateralmente e a qualquer tempo, para fins de adequação a futuras exigências
          legais, bem como para que possamos melhorar a sua experiência.
          Se as alterações feitas por nós forem consideradas substanciais por
          nossa equipe, nós nos comprometemos a informar-lhe acerca das mudanças
          realizadas através de banner em nosso site.
          Você tem a decisão de aceitar as nossas alterações ou não e, em caso
          negativo, aconselhamos-lhe a parar de acessar a nossa plataforma. Porém, você
          sabe que sempre poderá contar conosco para sanar eventuais dúvidas também
          a respeito dessas alterações, bem como sabe que estamos sempre abertos ao
          diálogo.</p>
        <br />
        <h3 className="font-semibold">DA POLÍTICA DE PRIVACIDADE</h3>
        <p>Nós respeitamos a sua privacidade e prezamos pela proteção dos seus
          dados pessoais e dados pessoais sensíveis. Portanto, temos uma Política de
          Privacidade em vigor, a qual pode ser consultada por você a qualquer momento
          em nossa plataforma.</p>
        <br />
        <h3 className="font-semibold">DO FORO</h3>
        <p>Para a solução de controvérsias decorrentes do presente instrumento de
          Termos de Uso serão aplicadas as normas vigentes na República Federativa do
          Brasil e os eventuais litígios deverão ser apresentados no foro da comarca de
          Recife/PE.
          Quaisquer sugestões ou perguntas sobre estes termos de uso do site
          <a href="http://www.labpas.com.br/"> http://www.labpas.com.br/</a>, é só enviar um e-mail para o endereço
          privacidade@labpas.com.br que nossa data protection officer (encarregada de
          dados) terá o maior prazer em atendê-lo(a).
        </p>
        <br />
        <h4 className="font-semibold">Em vigor desde: 12 de junho de 2023.</h4>
      </div>

      <Footer />
    </>
  );
}

export default Tests;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import { Icon } from '@iconify/react';

function LoginForm({ user }) {
  const [userInfo, setUserInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  // console.log(userInfo, 'xxxxxxxxxxxxxx');

  function handleChange(event) {
    const { name, value } = event.target;
    setUserInfo((prevState) => ({ ...prevState, [name]: value }));
  }

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  if (user === 'paciente') {
    return (
      <div className=" bg-neutral-50 border-2 border-black flex items-center justify-center m-2 p-10 max-sm:m-4 max-sm:p-4">
        <form target="_blank" className="border border-neutral-600 flex flex-col justify-center items-center p-4 w-1/3 max-sm:w-full text-lg" action="https://pathoweb.com.br/site/baixarLaudos">
          <p className="my-2 text-neutral-600">Acessar o sistema Pathoweb</p>
          <input type="hidden" name="empresa" value="14" readOnly />
          <label htmlFor="numero" className="border border-neutral-600 w-full my-1">
            <input required id="numero" className="p-2 outline-none w-full bg-neutral-50 placeholder:text-neutral-400 caret-neutral-600" name="numero" type="text" value={userInfo.numero || ''} onChange={handleChange} placeholder="Digite o número do exame" />
          </label>
          <label htmlFor="senha" className="border border-neutral-600 w-full my-1 flex items-center">
            <input required id="senha" className="p-2 outline-none w-full bg-neutral-50 placeholder:text-neutral-400 caret-neutral-600" name="senha" type={showPassword ? 'text' : 'password'} value={userInfo.senha || ''} onChange={handleChange} placeholder="Digite a senha" />
            <Icon icon={showPassword ? 'mdi:eye' : 'mdi:eye-off'} onClick={togglePasswordVisibility} className="transition-all ease-linear" />
          </label>
          <button
            className="bg-med-cyan text-white py-2 px-6 my-2 uppercase text-lg hover:bg-dark-cyan transition-all ease-in"
            type="submit"
            value="Consultar"
          >
            consultar
          </button>
        </form>
      </div>
    );
  }
  return (
    <div className="border-2 border-black bg-neutral-50 flex justify-center items-center m-2 p-10 max-sm:m-4 max-sm:p-4">
      <form action="https://pathoweb.com.br/site/j_spring_security_check" method="POST" target="_blank" className="flex flex-col justify-center items-center p-4 w-1/3 max-sm:w-full text-lg border border-neutral-600">
        <p className="my-2 text-neutral-600">Acessar o sistema Pathoweb</p>
        <label htmlFor="j_username" className="my-1 border border-neutral-600 w-full">
          <input required id="j_username" className="p-2 outline-none w-full caret-neutral-600 bg-neutral-50 placeholder:text-neutral-400" name="j_username" type="text" value={userInfo.j_username || ''} onChange={handleChange} placeholder="Digite o usuário" />
        </label>
        <label htmlFor="j_password" className="my-1 border border-neutral-600 w-full flex items-center">
          <input required id="j_password" className="p-2 outline-none w-full caret-neutral-600 bg-neutral-50 placeholder:text-neutral-400" name="j_password" type={showPassword ? 'text' : 'password'} value={userInfo.j_password || ''} onChange={handleChange} placeholder="Digite sua senha" />
          <Icon icon={showPassword ? 'mdi:eye' : 'mdi:eye-off'} onClick={togglePasswordVisibility} className="transition-all ease-linear" />
        </label>
        <button className="bg-med-cyan text-white py-2 px-6 my-2 uppercase text-lg hover:bg-dark-cyan transition-all ease-in" type="submit" value="entrar">entrar</button>
      </form>
    </div>
  );
}

export default LoginForm;

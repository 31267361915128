import React from 'react';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import Navbar from '../components/Navbar';
import foto from '../assets/foto_quem somos.jpg';
import bg from '../assets/Novos_banner-quemsomos.jpg';
import vetor from '../assets/arabesco_2.png';

function QuemSomos() {
  return (
    <>
      <Navbar />
      <Banner image={bg} imgalt="imagem de fundo" text="Quem Somos" hasbutton={false} />
      <div className="flex items-center px-10 lg:px-32 max-sm:px-10 py-14 pb-20 relative max-md:flex-wrap">
        <div className="flex items-center justify-center w-1/2 lg:w-1/3 max-md:w-full max-sm:mb-6">
          <div className="rounded-3xl overflow-clip">
            <img src={foto} alt="imagem de destaque" className="object-contain" />
          </div>
        </div>
        <div className="px-4 py-2 flex-1">
          <h3 className="text-2xl font-semibold text-med-cyan">
            DIAGNÓSTICOS MAIS ASSERTIVOS,
            <br />
            TRATAMENTOS MAIS ADEQUADOS.
          </h3>
          <p className=" text-base text-justify max-sm:text-left text-dark-blue font-semibold py-1 pb-5">
            O avanço da medicina está ampliando o acesso a tratamentos eficazes e bem-sucedidos.
            Todos os dias surgem novas abordagens, terapias, procedimentos e possibilidades de cura
            para diversos problemas de saúde. Mas para definir a melhor conduta em cada caso
            específico, é preciso saber exatamente com que tipo de doença estamos lidando.
          </p>
          <h3 className="text-2xl font-semibold text-med-cyan">
            ANATOMIA PATOLÓGICA COMO DEFINIDORA
            <br />
            DE CONDUTAS MÉDICAS.
          </h3>
          <p className=" text-base text-justify max-sm:text-left text-dark-blue font-semibold py-1 pb-5">
            O Laboratório PAS é referência em Patologia Cirúrgica e Citopatologia, especialidades
            médicas que interpretam as alterações das células, tecidos e órgãos, a partir de
            amostras provenientes de punções, biópsias e de peças cirúrgicas. Associados à análise
            de achados clínicos, os laudos gerados pelos médicos patologistas do LabPAS contribuem
            decisivamente para a definição da doença e seu prognóstico, auxiliando profissionais de
            saúde no tratamento de seus pacientes.
          </p>
          <h3 className="text-2xl font-semibold text-med-cyan">
            SUPERAR INCERTEZAS E AUMENTAR AS CHANCES
            <br />
            DE CURA DAS PESSOAS.
          </h3>
          <p className=" text-base text-justify max-sm:text-left text-dark-blue font-semibold py-1 pb-5">
            Somos movidos pelo compromisso de desenvolver uma patologia de qualidade e precisão,
            priorizando a boa prática médica, a ética e a reponsabilidade. Valorizamos o contato
            próximo com o médico assistente para discussão de casos e atualização de temas.
            Atuamos de forma atenta e cuidadosa, por meio de técnicas apuradas e soma de expertises
            em busca de fornecer, através do laudo, informações úteis para o melhor tratamento do
            paciente.
          </p>
        </div>
        <div className="h-80 w-80 z-10 absolute -bottom-24 -left-10 max-[640px]:hidden">
          <img src={vetor} alt="imagem decorativa" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default QuemSomos;

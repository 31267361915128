import React from 'react';
import './styles.css';
import { Chrono } from 'react-chrono';
import data from '../data/tldata';

export default function Timeline() {
  return (
    <div className="Timeline">
      <div style={{ width: '95%' }}>
        <Chrono
          items={data}
          mode="HORIZONTAL"
          showAllCardsHorizontal
          itemWidth={300}
          cardWidth={300}
          cardHeight={100}
          contentDetailsHeight={100}
          fontSizes={{
            title: '1rem',
          }}
          slideShow
          cardPositionHorizontal="TOP"
          theme={{
            primary: '#42BBC9',
            secondary: '#117F99',
            cardBgColor: 'white',
            titleColor: '#00679A',
            titleColorActive: '#BBE2EE',
          }}
          buttonTexts={{
            first: 'Ir para o primeiro',
            last: 'Ir para o último',
            next: 'Próximo',
            previous: 'Anterior',
            play: 'Apresentar como slideshow',
            stop: 'Parar apresentação de slideshow',
          }}
          timelinePointShape="circle"
          timelinePointDimension={30}
        />
      </div>
    </div>
  );
}
